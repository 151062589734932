import _ from 'lodash';
import { SpecPathValue } from './SpecPathValue';
import AskAI from '../../../components/ai/AskAI';
import { useContext } from 'react';
import LegoAdminPageContext from '../../legoAdminPageContext';
import { IconButton } from '../../../components/common/IconButton';
import { SchemaChangeOpsList } from './SchemaChangeOpsList';


export default function SpecsSchemaChangePreview({selection = null, onSelectionChange = null, changes, usage}) {
  const {page} = useContext(LegoAdminPageContext);

  const explainWithAI = async (text) => {
    page.openModal(<div style={{maxWidth: '800px', width: '80vw'}}>
      <AskAI prompt={`What does this spec mean? Explain in a few sentences, analyze each part of the specs in a brief paragraph\n${text}`}/>
    </div>, {minSize: false, title: 'Explain with AI'});
  };

  return <div>  
    <div className={'p-1 bg-white'} style={{fontSize: '15px'}}>
      <SchemaChangeOpsList selection={selection} onSelectionChange={onSelectionChange} ops={changes}/>
    </div>


    {_.map(usage, (specs, text) => <div key={text} className={'mt-2'}>
      <div className={'d-flex align-items-center'}>
        <IconButton icon={'tips_and_updates'} className={'mr-2 align-self-start'} level={'primary'} onClick={() => explainWithAI(text)} description={'Explain with AI'}/>

        <span className={'small'}>
            "{text}"
          </span>
      </div>

      <div>
        <div className={'py-1 px-3 rounded monospace small break-word-all'} style={{backgroundColor: '#ddd'}}>
          {specs.map(([path, value]) => <SpecPathValue key={path} path={path} value={value}/>)}
        </div>
      </div>
    </div>)}
  </div>
}
