import React, { Component } from 'react';
import _ from 'lodash';
import { IconButton } from '../IconButton';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import EventEmitter from "events";
import ModalManager from '../../ModalManager';

dayjs.extend(relativeTime);

export default class CRUDEditor extends Component {
  constructor(props) {
    super(props);

    this.modalActionsBus = new EventEmitter();

    this.state = {
      original: this.props.obj,
      changedObject: _.cloneDeep(this.props.obj)
    };
  }

  editField(fieldPath, newValue) {
    let updated = this.state.changedObject;
    _.set(updated, fieldPath, newValue);
    this.setState({ changedObject: updated });
    this.checkChanges();
  }

  checkChanges() {
    const areEqual = _.isEqual(this.state.changedObject, this.state.original);
    this.setState({ changed: !areEqual });
  }

  /**
   *
   * @param {Object} changedObject
   * @returns JSX.Element
   */
  getEditorUi(changedObject) {
    throw new Error("Not implemented by subclass")
  }

  handleError(error) {
    // this.modalErrorsBus.emit('open', <div>
    //   <div className={'alert alert-danger'}>{ error.errmsg || error.toString() }</div>
    // </div>, false);
    alert(error)
    console.error(error)
  }

  async saveChanges() {
    let updatedDocument = await this.props.onSave(this.state.changedObject);
    // It could fail and return undefined
    if(updatedDocument) {
      this.setState({ original: updatedDocument, changedObject: _.cloneDeep(updatedDocument) })
      this.checkChanges();
    }
  }

  render() {
    let { changedObject } = this.state;

    let { _id, __unsaved, __new, __deleted, createdAt, updatedAt } = changedObject;

    let rowClasses = 'p-1 ';
    if (__new) {
      rowClasses += ' bg-light-success';
    }
    if (__unsaved) {
      rowClasses += ' bg-light-warning';
    }
    if (__deleted) {
      rowClasses += ' bg-light-danger';
    }

    if (this.state.changed) {
      rowClasses += 'bg-light-warn';
    }

    let editTime = '';
    if (updatedAt) {
      editTime = ((updatedAt === createdAt) ? 'Created ' : 'Edited ') + dayjs(updatedAt).fromNow();
    }

    return <div className={'rounded ' + rowClasses}>
      <h5>
        Editar <span className={'text-secondary small'}>{_id}</span>

        <IconButton icon={'code'} level={'primary'} onClick={() => {
          this.modalActionsBus.emit('open', <div>
            {/*<div className={'p-2 bg-light monospace break-word-all'}>*/}
            {/*  <TextDiff type={'chars'} inputB={JSON.stringify(this.state.changedObject, true, 2)} inputA={JSON.stringify(this.state.original, true, 2)}/>*/}
            {/*</div>*/}
            <pre className={'small'}>{JSON.stringify(this.state.changedObject, true, 2)}</pre>
          </div>, false);
        }}/>

        &nbsp;<span className={'text-info small ml-2 mr-2 no'} title={updatedAt}>{editTime}</span>

      </h5>

      <div className={'bg-white rounded'}>{ this.getEditorUi(changedObject) }</div>

      <div className={'mt-3'}>
        <button onClick={() => this.props.onSave(changedObject, true)} className={'btn btn-primary mr-2'}>Ok</button>
        <button onClick={() => this.props.onCancel()} className={'btn btn-default mr-2'}>Cancelar</button>

        {
          this.state.changed
            ?
            <button onClick={async () => this.saveChanges()} className={'btn btn-success'}>Save changes</button>
            :
            null
        }
      </div>

      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}
