import React, {useRef, useState} from 'react';
import _ from 'lodash';

import Async from 'react-select/lib/Async';
import FacetId, {idOfPropDefinitionRegex} from "./FacetId";
import FacetsProxy from "./FacetsProxy";

// As functional react components can't have refs, React.forwardRef is needed to be able to forward the users of
// FacetIdsSelector to the Select input (in order to call focus() on it from the outside)
/** @type {import('react').ForwardRefExoticComponent<any & {ref: import('react').Ref<{getOperationsHistory: function(): Array, applyOperations: function(Array): void}>}>} */
export default React.forwardRef(function FacetIdsSelector({ value, singleValue, selectedId, onChange, config, service, ...props }, ref) {
  let fallbackRef = useRef(null);
  ref = ref || fallbackRef;

  const handleSelectChange = (values, actionMeta) => {
    if(singleValue) {
      onChange(_.map(values, 'value').slice(-1));
    } else {
      onChange(_.map(values, 'value'));
    }
  }

  const handleClickEditFacet = (id) => {
    // TODO: HACK to allow editing a long key without typing it all over again
    if (value && value.length > 0 && ref && ref.current) {
      if(!singleValue) {
        onChange(_.without(value, id));
      }
      // TODO: HACK OF THE HACK. Manipulating the most internal react-select properties (very fragile)
      // Based on looking at: https://github.com/JedWatson/react-select/blob/v1.x/src/Select.js
      // ref.current.select.onInputChange(id);
      ref.current.select.select.handleInputChange ({target: {value: id}, currentTarget: {value: id}});
    }
  };

  const getOption = (props) => {
    let { innerProps, isSelected, isFocused } = props;
    let { innerRef, ...filteredProps } = innerProps;

    return <div {...filteredProps} className={'p-1 ' + ((isSelected || isFocused) ? 'bg-light-primary' : '')}>
      {props.data.isNew ? 'Use unknown ' : null}
      <FacetId id={props.data.value} config={config}/>
    </div>
  };


  const getMultiValueLabel = (props) => {
    let [[field, val]] = props.data.value ? _.toPairs(props.data.value) : _.toPairs(props.data);
    return <FacetId id={props.data.value} config={config} onClick={() => handleClickEditFacet(props.data.value)}/>
  };


  // Workaround needed to have Shift+Home selection functionality in react select
  const handleKeyDown = (evt) => {
    switch (evt.key) {
      case "Home":
        evt.preventDefault();
        if (evt.shiftKey) evt.target.selectionStart = 0;
        else evt.target.setSelectionRange(0, 0);
        break;
      case "End":
        evt.preventDefault();
        const len = evt.target.value.length;
        if (evt.shiftKey) evt.target.selectionEnd = len;
        else evt.target.setSelectionRange(len, len);
        break;
    }
  };

  // if there is a selectedId prop, only ONCE call handleSelectChange
  const [initialSelectedId, setIntialId] = useState(selectedId);
  if(initialSelectedId) {
    setIntialId(null);
    // TODO: Another dirty hack of Async Select. The autocomplete does not seem to work doing this
    // Also, use setTimeout to prevent failure on first load
    setTimeout(() => {
      // Ensure this is saved in state so that it is not called again
      handleClickEditFacet(initialSelectedId.trim());
    }, 1)
  }


  let values = _.map(value, (id) => ({ label: id, value: id }));

  let loadFacetsAutocompleteList = async (input) => {
    const name = id => FacetsProxy.parseId(id).name;

    const isNotFacetProperty = v => !v.match(idOfPropDefinitionRegex);
    const idEqualsInput = v => v.toLowerCase().toString() === input.toLowerCase();
    const nameEqualsInput = v => name(v).toLowerCase().toString() === input.toLowerCase();
    const valueStartsWithInput = v => v.toLowerCase().toString().startsWith(input.toLowerCase());
    const nameStartsWithInput = v => name(v).toLowerCase().toString().startsWith(input.toLowerCase());
    const nameEndsWithInput = v => name(v).toLowerCase().toString().endsWith(input.toLowerCase());
    const shorterFirst = v => -v.length;

    let allCaseInput = _.map(input, c => `[${_.uniq([c.toUpperCase(), c.toLowerCase()]).join('')}]`).join('');

    const inputRegex = new RegExp(_.escapeRegExp(input), 'gi');

    let matchingIds = _.filter(_.keys(config.mainMenu), id => id.match(inputRegex))

    if (input.length > 2) {
      matchingIds = await service.findMatches(`*${allCaseInput}*`);
    }

    matchingIds = _.sortBy(matchingIds, idEqualsInput, nameEqualsInput, isNotFacetProperty, valueStartsWithInput, nameStartsWithInput, nameEndsWithInput, shorterFirst).reverse();

    const options = [..._.map(matchingIds.slice(0, 50), id => ({ label: id, value: id }))];

    if(!_.includes(matchingIds, input)) {
      options.push({ label: input, value: input, isNew: true });
    }

    return options;
  };

  return (
    <Async
      closeOnSelect={true}
      isMulti
      ref={ref}
      components={{
        Option: getOption,
        MultiValueLabel: getMultiValueLabel,
      }}
      getOptionValue={(option) => JSON.stringify(option)}
      onChange={handleSelectChange}
      loadOptions={loadFacetsAutocompleteList}
      placeholder="Find facet by id or a word..."
      openOnFocus={true}
      menuShouldScrollIntoView={false}
      maxMenuHeight={270}
      // menuPositionunion={'fixed'}
      onKeyDown={handleKeyDown}
      value={values}
      {...props}
    />
  );
})
