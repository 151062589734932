import React, {Component} from 'react';
import SocketStatus from "../components/SocketStatus";

import _ from "lodash";
import EventEmitter from "events";
import PageHeader from '../components/PageHeader';
import ModalManager from '../components/ModalManager';
import {LoadingOverlay} from '../components/common/LoadingOverlay';
import GlobalClipboardZone from '../components/common/GlobalClipboardZone';
import HotKeys from 'react-hotkeys/es/HotKeys';
import {ShortcutsManager} from '../components/common/ShortcutsManager';
import menu from './menus/menu-main'
import GridLayoutHeader from "../components/common/layout/GridLayoutHeader";
// import HotKeys from 'react-hotkeys';

import LegoAdminPageContext from './legoAdminPageContext';
import LoginPage from './LoginPage';
import InPageNotifications from '../components/common/InPageNotifications';
import TaskInProgressWidget from '../components/dataprocess/TaskInProgressWidget';


export default class LivePage extends Component {
  constructor(props) {
    super(props);

    this.liveService = client.service('services/live-updates');
    this.liveServicesChannels = new Set();

    // Bus to show modals
    this.modalActionsBus = new EventEmitter();
    this.modalErrorsBus = new EventEmitter();
    this.actionsHandler = new EventEmitter();

    // Shortcuts
    this.shortcuts = new ShortcutsManager();

    // Feather client, exposed for subclasses
    this.client = client;

    // Subclasses can define remote commands they handle
    this.commandHandlers = {};

    this.uiCommandsService = client.service('services/ui-commands');
    this.sessionId = "wisession";

    this.state = {
      connectionStatus: initialSocketStatus,
      sessionId: this.sessionId,
      pageIsProcessing: 0,
      loadingMessage: 'Processing...',
      notifications: []
    };

    this.services = {};

    this.handleRemoteCommand = this.handleRemoteCommand.bind(this);
    window.executeRemoteCommand = this.executeRemoteCommand.bind(this);
    this.onGlobalUnhandledPromise = this.onGlobalUnhandledPromise.bind(this);

    this.throttledNotificationUpdate = _.throttle(this.throttledNotificationUpdate.bind(this), 1000)

    this.menu = menu;

    this.pageContext = {
      page: this,
      config: window.config, // TODO: Fast hack. The correct way is that index.js creates a context that the page can consume
    };

    // Can be redefined by subclasses in constructor
    this.submenu = [];

    this.tabsChannel = new BroadcastChannel("LegoAdminTab");
    this.onActivityMonitoring = _.throttle(this.onActivityMonitoring.bind(this), 1000);
  }

  onActivityMonitoring() {
    // console.info(new Date().getTime()+" Broadcasting tab activity")
    this.tabsChannel.postMessage({ event: 'activity'});
  }

  service(serviceEndpoint) {
    this.services[serviceEndpoint] = this.client.service(serviceEndpoint)
    return this.services[serviceEndpoint];
  }

  listenObjectLiveEvents(service, objectId) {
    this.liveService.create({service, id: objectId ||'all'}).then(channelId => this.liveServicesChannels.add(channelId));
  }

  leaveLiveEventsChannels() {
    this.liveServicesChannels.forEach(uiCommandsChannelId => this.liveService.remove(uiCommandsChannelId));

    this.liveServicesChannels.clear();
  }

  componentDidMount() {
    this.mounted = true;
    _.each(this.services, (service, name) => {
      // this.listenObjectLiveEvents(service, this.props.testSetId);
    })

    this.shortcuts.onUpdate(() => this.forceUpdate());

    this.uiCommandsService.on('created', this.handleRemoteCommand);

    this.socketStatus = new SocketStatus(window.socket);
    this.socketStatus.onLifecyleEvent((eventName, params) => this.setState({connectionStatus: eventName+' '+params}));
    this.socketStatus.on('connect', () => {
      this.setState({connectionStatus: 'connected'});
      this.listenObjectLiveEvents('ui-commands', this.sessionId);
      this.onConnectionReconnect();
    });

    if(!this.isSubpage) {
      window.addEventListener("unhandledrejection", this.onGlobalUnhandledPromise);
      $('html').click(this.onActivityMonitoring);
      $('html').keydown(this.onActivityMonitoring);
    }

    if(this.props.location)
      document.title = 'Legos '+this.props.location.pathname;
  }

  componentWillUnmount() {
    this.socketStatus?.destroyHandlers();

    this.uiCommandsService.removeListener('created', this.handleRemoteCommand);

    this.leaveLiveEventsChannels();
    window.removeEventListener("unhandledrejection", this.onGlobalUnhandledPromise);
    this.mounted = false;

    if(!this.isSubpage) {
      $('html').unbind('click', this.onActivityMonitoring);
      $('html').unbind('keydown', this.onActivityMonitoring);
      this.tabsChannel.close();
    }
  }

  onGlobalUnhandledPromise(event) {
    if(event.reason?.code === 401 && event.reason.name === 'NotAuthenticated') {
      this.count = this.count || 0;
      console.log('Opening login '+this.count++);
      this.openLogin();
      event.preventDefault();
    }
  }

  onConnectionReconnect() {
    // Can be overriden by subclasses
  }

  executeCommand(command, params) {
    this.executeRemoteCommand(this.sessionId, command, params);
  }

  executeRemoteCommand(sessionId, command, params, className) {
    this.uiCommandsService.create({sessionId, command, params, className});
  }

  registerRemoteCommands(newCommands) {
    this.commandHandlers = {... this.commandHandlers, ... newCommands};
  }

  handleRemoteCommand({command, params, className}) {
    if(className && this.constructor.name !== className) {
      return;
    }

    const cmdHandler = this.commandHandlers[command];
    if(cmdHandler) {
      console.log(`%c[${this.sessionId}] REMOTE COMMAND ${command} ${JSON.stringify(params).slice(0,100)}`, 'color: green;background: #EEE;')
      cmdHandler(... params);
    } else {
      console.log(`%c[${this.sessionId}] INVALID REMOTE COMMAND ${command} ${JSON.stringify(params).slice(0,100)}`, 'color: red;background: #EEE;')
    }
  }

  action(actionId, ... actionArgs) {
    this.actionsHandler.emit(actionId, ... actionArgs);
  }

  onAction(actionId, handler) {
    this.actionsHandler.on(actionId, handler);
  }

  hasActionHandler(actionId) {
    return this.actionsHandler.listenerCount(actionId) > 0
  }

  offAction(actionId, handler) {
    this.actionsHandler.off(actionId, handler);
  }

  getLoggedUserSignature() {
    if(window.user) {
      return {
        username: window.user.username,
        id: window.user.id
      };
    } else {
      return null;
    }
  }

  getUrlParamComponent(param) {
    let val = new URLSearchParams(document.location.search).get(param);
    return val ? new URLSearchParams([[ param, val ]]).toString() : ''
  }

  getUrlParam(param, isJSON = true) {
    // read params from url query string
    const params = new URLSearchParams(this.props.location.search);
    const val = params.get(param);

    if(isJSON) {
      try {
        return val ? JSON.parse(val) : val;
      } catch (e) {
        return undefined;
      }
    } else {
      return val || '';
    }
  }

  setUrlParams(paramsKeyValueObj) {
    const params =  new URLSearchParams(this.props.location.search);
    _.each(paramsKeyValueObj, (v,k) => params.set(k, JSON.stringify(v)));
    this.props.history.push({pathname: this.props.location.pathname, search: `?${params.toString()}`});
  }

  setUrlParam(paramKey, paramValue) {
    this.setUrlParams({[paramKey]: paramValue});
  }

  deleteUrlParam(...paramKeys) {
    const params =  new URLSearchParams(this.props.location.search);
    for (const paramKey of paramKeys){
      params.delete(paramKey);
    }
    this.props.history.push({pathname: this.props.location.pathname, search: `?${params.toString()}`});
  }

  /**
   *
   * @param {Promise | function} promise
   * @param {string} [message]
   * @param {function} [doneCbk]
   * @returns {Promise<*>}
   */
  runAsync(promise, message = 'Processing...', doneCbk) {
    const noOp = () => true;

    // Assume it is either a promise or an async function to be invoked
    if(typeof(promise) === 'function') {
      try {
        promise = promise();
      } catch (err) {
        console.error(err);
        alert(err);
        return;
      }
    }

    // This can be called concurrently, to avoid incorrect counts, must be done atomically using react callback
    this.setState(({pageIsProcessing}) => ({ pageIsProcessing: pageIsProcessing+1, loadingMessage: message }));

    promise.then(doneCbk || noOp).catch(err => {
      if(err.code === 401) {
        this.openLogin();
      } else {
        console.error(err);
        alert(err);
      }
    }).finally(() => {
      this.setState(({pageIsProcessing}) => ({ pageIsProcessing: pageIsProcessing - 1 }));
    });
    return promise;
  }

  openModal(modalComponent, options) {
    this.modalActionsBus.emit('open', modalComponent, options)
  }

  closeModal() {
    this.modalActionsBus.emit('close');
  }

  openModalSubpage(SubpageComponentClass, extraProps = {}) {
    this.modalActionsBus.emit('open', <SubpageComponentClass {...this.props} {...extraProps}/>)
  }

  renderPageBody() {
    console.error('Subclasses of LivePage should override renderPageBody or render altogether')
    return null;
  }

  openLogin() {
    if(!window.loginModalOpen) {
      window.loginModalOpen = true;
      this.openModal(<LoginPage modal={true} key={'initial'} initialEmail={localStorage['lastAccessEmail']}
                                onLogin={() => {
                                  this.closeModal();
                                }}/>, { minSize: false, title: 'Please login to continue', onClose: () => window.loginModalOpen = false })
    }
  }

  /**
   *
   * @param notification {level: string, title: string, body: string, time: Date}
   */
  showNotification(notification) {
    this.state.notifications.push(notification);
    this.throttledNotificationUpdate();
    setTimeout(() => this.closeNotification([notification]), 1000*60*5);
  }

  throttledNotificationUpdate() {
    this.setState({nofications: this.state.notifications});
  }


  closeNotification(notifs) {
    this.setState({notifications: _.difference(this.state.notifications, notifs)});
  }

  render() {
    let { pageIsProcessing, loadingMessage, sessionId, connectionStatus } = this.state;

    const bodyClass = (!connectionStatus || connectionStatus === 'connected') ? '' : 'black-and-white';

    let body;
    try {
      body = this.renderPageBody();
    } catch (err) {
      body = <div className={'alert alert-danger'}>
        <h3>Error rendering page body</h3>
        <pre className={'font-weight-bold mb-2'}>{err.toString()}</pre>
        <pre>{err.stack}</pre>
      </div>
    }

    if (this.fullScreen) {
      return <LegoAdminPageContext.Provider value={this.pageContext}>
        <HotKeys keyMap={this.shortcuts.keyMappings} handlers={this.shortcuts.handlers}>
          <GridLayoutHeader className={bodyClass}>
            <PageHeader location={this.props.location} menu={this.menu} submenu={this.submenu}
                        connectionStatus={connectionStatus} sessionId={sessionId}/>

            {body}
          </GridLayoutHeader>

          <TaskInProgressWidget/>
          <InPageNotifications notifications={this.state.notifications} onClose={(notifs) => this.closeNotification(notifs)}/>
          <ModalManager actionsBus={this.modalActionsBus}/>
          <ModalManager actionsBus={this.modalErrorsBus}/>
          <LoadingOverlay isLoading={pageIsProcessing > 0} loadingMessage={loadingMessage}/>
          <GlobalClipboardZone/>
        </HotKeys>
      </LegoAdminPageContext.Provider>;
    } else {
      return <LegoAdminPageContext.Provider value={this.pageContext}>
        <HotKeys keyMap={this.shortcuts.keyMappings} handlers={this.shortcuts.handlers}>
          <div className={bodyClass}>
            <PageHeader location={this.props.location} menu={this.menu} submenu={this.submenu}
                        connectionStatus={connectionStatus} sessionId={sessionId}/>

            {body}

            <InPageNotifications notifications={this.state.notifications} onClose={(notifs) => this.closeNotification(notifs)}/>
            <TaskInProgressWidget/>
            <ModalManager actionsBus={this.modalActionsBus}/>
            <ModalManager actionsBus={this.modalErrorsBus}/>
            <LoadingOverlay isLoading={pageIsProcessing > 0} loadingMessage={loadingMessage}/>
            <GlobalClipboardZone/>
          </div>
        </HotKeys>
      </LegoAdminPageContext.Provider>;
    }
  }
}
