import React from 'react';
import * as jsdiff from 'diff';

/** @type {Record<string, (a: string, b: string, options?: import('diff').BaseOptions) => import('diff').Change[]>} */
const fnMap = {
  'chars': jsdiff.diffChars,
  'words': jsdiff.diffWords,
  'sentences': jsdiff.diffSentences,
  'json': jsdiff.diffJson
};

const breakInLines = text => {
  let lines = text.replace(/ /g, '\u00a0').split('\n');
  return lines.map((item, index) => {
    if(index < (lines.length - 1)) {
      return (<span key={index}>{item}<br/></span>);
    } else {
      return (<span key={index}>{item}</span>);
    }
  });
};

/**
 * Component that shows differences between two texts
 * @param {Object} props
 * @param {string|Object} props.inputA - First text to compare
 * @param {string|Object} props.inputB - Second text to compare
 * @param {'chars'|'words'|'sentences'|'json'} props.type - Type of diff to perform
 * @param {number} [props.ellipsis] - Number of characters to show before ellipsis
 * @returns {JSX.Element}
 */
function TextDiff({inputA = '', inputB = '', type = 'chars', ellipsis}) {
  const diff = fnMap[type](inputA, inputB);
  const result = diff.map((part, index) => {
    if(part.added) {
      return <ins key={index}>{breakInLines(part.value)}</ins>
    } else if(part.removed) {
      return <del key={index}>{breakInLines(part.value)}</del>
    } else {
      if(ellipsis && part.value.length > ellipsis) {
        let start = part.value.slice(0, ellipsis/2);
        let end = part.value.slice(ellipsis/2).slice(-ellipsis/2);
        return <span key={index}>{breakInLines(start)} <br/><span className={'badge badge-dark'}>(...)</span><br/> {breakInLines(end)}</span>
      } else {
        return <span key={index}>{breakInLines(part.value)}</span>
      }
    }
  });

  return (
    <span className='diff-result' style={{lineHeight: '1.3em'}}>
      {result}
    </span>
  );
}

export default TextDiff;
