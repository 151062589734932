import React from 'react';
import _ from 'lodash';

import TextareaAutosize from 'react-textarea-autosize';

export default function MultilineTextEditor({
  minRows = 1,
  maxRows = 20,
  onChange,
  onBlur = null,
  value,
  className = '',
  small = false,
  ...other
}) {
  let extraClasses = ' ' + (className || '');
  if (small) {
    extraClasses += ' form-control-sm';
  }

  return (
    <TextareaAutosize
      minRows={minRows || 1}
      maxRows={maxRows || 20}
      type={'text'}
      className={'form-control form-control-sm' + (true ? '' : 'text-danger') + extraClasses}
      onChange={e => onChange(e.target.value)}
      onBlur={onBlur ? e => onBlur(e.target.value) : null}
      value={value}
      {...other}
    />
  );
}
