import React, { useState, useRef, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';

import TextSearchInput from '../../../components/common/TextSearchInput';
import { IconButton } from '../../../components/common/IconButton';
import LegoAdminPageContext from '../../legoAdminPageContext';
import PromptModal from '../../../components/common/editors/PromptModal';
import SchemaTree from './SchemaTree';

const validFieldsByType = {
  'object': ['properties'],
  'array': ['items'],
  'string': [],
  'number': [],
  'boolean': [],
  'enum': ['values'],
  'objectTypedKeys': ['keys', 'valuesType'],
  'unit': ['unit'],
  'union': ['types']
}

export function sortObjectKeysDeep(object) {
  if(_.isPlainObject(object)) {
    const result = {};
    _.forEach(_.sortBy(Object.keys(object), k => k.toLowerCase()), key => {
      result[key] = sortObjectKeysDeep(object[key]);
    });
    return result;
  } else {
    return object;
  }
}

export function sortObjectKeysShallow(object) {
  if(_.isPlainObject(object)) {
    const result = {};
    _.forEach(_.sortBy(Object.keys(object), k => k.toLowerCase()), key => {
      result[key] = object[key];
    });
    return result;
  } else {
    return object;
  }
}

// Find add-delete of the same keys that cancel each other
function simplifyOperations(operations) {
  //TODO: Its tricky what happens if a subtree is cloned or moved. Think better about this

  // let filteredOps = [];
  // for(const currentOp of operations) {
  //   if(currentOp.op === 'remove') {
  //     // A remove is neutralized only if the exact path was previously added
  //     if(!_.some(filteredOps, {op: 'add', path: currentOp.path})) {
  //       filteredOps.push(currentOp);
  //     }
  //     // If a prop was added (it means it wasn't there) and then deleted, both ops neutralize, and any child modification too
  //     filteredOps = _.filter(filteredOps, ({op, path}) => !(path.startsWith(currentOp.path+'.') || path === currentOp.path));
  //   } else {
  //     filteredOps.push(currentOp);
  //   }
  // }
  // return filteredOps;

  return operations
}


/**
 * @typedef {Object} SchemaEditorProps
 * @property {Object} value - The schema value
 * @property {function(Object, Array): void} onChange - Callback when schema changes
 */

/** @type {import('react').ForwardRefExoticComponent<SchemaEditorProps & {ref: import('react').Ref<{getOperationsHistory: function(): Array, applyOperations: function(Array): void}>}>} */
const SpecsSchemaEditor = forwardRef(function SchemaEditor({value, onChange}, ref) {
  const { page } = useContext(LegoAdminPageContext);
  const [schema, setSchema] = useState(sortObjectKeysDeep(_.cloneDeep(value)));
  const [draggedNode, setDraggedNode] = useState(null);
  const [dragState, setDragState] = useState(null);
  const [collapsedNodes, setCollapsedNodes] = useState({});
  const [search, setSearch] = useState('')
  const [operationsHistory, setOperationsHistory] = useState([]);

  // Function to recursively set a default expanded state for all nodes
  const setAllNodesExpandedState = (obj, currentPath = '', newState = {}) => {
    if (!obj || typeof obj !== 'object') return newState;

    // Set this node's expanded state
    if (currentPath) {
      newState[currentPath] = true;
    }

    // Handle object properties
    if (obj.type === 'object' && obj.properties) {
      Object.keys(obj.properties).forEach(key => {
        const childPath = currentPath ? `${currentPath}.properties.${key}` : `properties.${key}`;
        setAllNodesExpandedState(obj.properties[key], childPath, newState);
      });
    }

    // Handle array items
    if (obj.type === 'array' && obj.items) {
      const childPath = `${currentPath}.items`;
      setAllNodesExpandedState(obj.items, childPath, newState);
    }

    // TODO: Handle objectTypedKeys

    // Handle union types
    if (obj.type === 'union' && obj.types) {
      obj.types.forEach((type, index) => {
        const childPath = `${currentPath}.types.${index}`;
        setAllNodesExpandedState(type, childPath, newState);
      });
    }

    return newState;
  };

  // Detect changes in the original prop and reset local state
  useEffect(() => {
    setOperationsHistory([]);
    let sortedSchema = sortObjectKeysDeep(_.cloneDeep(value));
    setSchema(sortedSchema);
    onChange && onChange(sortedSchema, []);
  }, [value]); // This runs whenever `data` changes

  // Initialize with all nodes expanded
  useEffect(() => {
    const expandedState = setAllNodesExpandedState(schema);
    setCollapsedNodes(expandedState);
  }, []);

  // Toggle node expansion
  const toggleExpand = (path, recursive = false, forceValue = undefined) => {
    setCollapsedNodes(prev => {
      return ({ ...prev, [path]: forceValue ?? !prev[path] });
    });

    if(recursive) {
      if(path) {
        _.each(_.get(schema, path, [])?.properties, (value, name) => toggleExpand(`${path}.properties.${name}`, true, forceValue));
      } else {
        _.each(schema.properties, (value, name) => toggleExpand(`properties.${name}`, true, forceValue));
      }
    }
  };

  const updateSchema = (newSchema, operations = null) => {
    newSchema = newSchema || schema;

    let newOperationsHistory = simplifyOperations([... operationsHistory, ...(operations || [])]);
    setOperationsHistory(newOperationsHistory);

    onChange && onChange(newSchema, newOperationsHistory);
  }

  // Update schema with a new value at a given path
  const updateSchemaPath = (pathOrOperations, optionalValue) => {
    if (!pathOrOperations || pathOrOperations === '') {
      throw new Error('Invalid empty paths');
    }

    // Update nested path
    if(_.isString(pathOrOperations)) {
      pathOrOperations = [[pathOrOperations, optionalValue]];
    }

    console.log("UPDATE", pathOrOperations);

    const operations = [];
    for(const [path, newValue] of pathOrOperations) {
      if (newValue === undefined) {
        _.unset(schema, path);
        operations.push({op: "remove", path});
      } else {
        if(_.get(schema, path) !== undefined) {
          operations.push({op: "remove", path});
        }
        _.set(schema, path, newValue);
        operations.push({op: "add", path, value: newValue});
      }
    }

    updateSchema(schema, operations);
  };

  const sortTouchedPath = (path) => {
    let parentPath = path.substring(0, path.lastIndexOf('.'));
    if(parentPath) {
      _.set(schema, parentPath, sortObjectKeysShallow(_.get(schema, parentPath)));
    }
  }

  const updateOps = {
    add(path, newValue) {
      if(_.get(schema, path) !== undefined) {
        throw new Error(`Cannot add path ${path} already exists`);
      }
      _.set(schema, path, newValue);
      operationsHistory.push({op: "add", path, value: newValue});
      sortTouchedPath(path);
    },
    update(path, newValue) {
      if(_.get(schema, path) !== undefined) {
        operationsHistory.push({op: "remove", path});
      }
      _.set(schema, path, newValue);
      operationsHistory.push({op: "add", path, value: newValue});
      sortTouchedPath(path);
    },
    remove(path) {
      _.unset(schema, path);
      operationsHistory.push({op: "remove", path});
    },
    addItem(path, value) {
      const items = _.get(schema, path, []);
      if(!_.isArray(items)) {
        throw new Error(`Cannot add item to non-array path ${path}`);
      }
      items.push(value);
      _.set(schema, path, items);
      operationsHistory.push({op: "addItem", path, value});    
    },
    move(fromPath, toPath) {
      if(_.get(schema, toPath) !== undefined) {
        throw new Error(`Cannot move to path ${toPath}, it would overwrite a value that already exists`);
      }

      const value = _.get(schema, fromPath);
      _.unset(schema, fromPath);
      _.set(schema, toPath, value);
      operationsHistory.push({op: "move", fromPath, toPath});
      sortTouchedPath(toPath);
    }
  }

  const updateOpsWithStateUpdate = _.mapValues(updateOps, (fn, key) => (...args) => {
    try {
      fn(...args);
      updateSchema();
    } catch(err) {
      alert(err.message);
    }
  });

  const applyOperations = (ops) => {
    for(const op of ops) {
      if(op.op === 'add') {
        updateOps.add(op.path, _.cloneDeep(op.value));
      } else if(op.op === 'remove') {
        updateOps.remove(op.path);
      } else if(op.op === 'move') {
        updateOps.move(op.fromPath, op.toPath);
      } else if(op.op === 'addItem') {
        updateOps.addItem(op.path, _.cloneDeep(op.value));
      }
    }  
    updateSchema();
  }

  // Handle starting drag operation
  const handleDragStart = (e, path, value) => {
    // Prevent dragstart on child nodes such as selecting text in editable labels
    if(e.currentTarget !== e.target) {
      e.preventDefault();
      return;
    }

    setDraggedNode({ path, value });
    setDragState({dragging: path});

    e.dataTransfer.setData('application/json', JSON.stringify({ path, value }));
    e.dataTransfer.effectAllowed = 'move';
  };

  // Handle dropping a node at a new location
  const handleDrop = (e, targetPath) => {
    e.preventDefault();
    if (!draggedNode) return;

    const { path: sourcePath, value } = draggedNode;

    // Don't drop onto itself or its children
    if (targetPath === sourcePath || targetPath.startsWith(`${sourcePath}.`)) {
      return;
    }

    // Get source node details
    const sourcePathParts = sourcePath.split('.');
    const sourceKey = sourcePathParts[sourcePathParts.length - 1];
    const sourceParentPath = sourcePathParts.slice(0, -1).join('.');

    // Get target node details
    const targetNode = targetPath ? _.get(schema, targetPath) : schema;

    // Determine where to place the node
    if (targetNode && targetNode.type === 'object' && sourceParentPath !== targetPath) {
      console.log('DRAG DROP from', sourcePath, 'to', targetPath)
      updateOpsWithStateUpdate.move(`${sourceParentPath}.${sourceKey}`, `${targetPath}.properties.${sourceKey}`);
      updateSchema();

      setDragState(null);
      setDraggedNode(null);

      e.stopPropagation();
    }
  };

  const handleDragOver = (e, targetPath) => {
    // Don't drop onto itself or its children
    const { path: sourcePath, value } = draggedNode;
    let parentPath = sourcePath.split('.').slice(0, -2).join('.');

    if (targetPath === sourcePath || targetPath.startsWith(`${sourcePath}.`) || parentPath === targetPath) {
      // console.log('DRAG OVER SKIPPED', targetPath)
      e.stopPropagation();
      if(dragState?.draggingOver) {
        setDragState({ ...dragState, draggingOver: null });
      }
      return;
    }

    if (_.get(schema, targetPath).type !== 'object') {
      return;
    }

    if(dragState?.draggingOver !== targetPath) {
      setDragState({ ...dragState, draggingOver: targetPath });
    }
    e.dataTransfer.dropEffect = 'move';
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnd = (e) => {
    console.log('DRAG END', dragState?.draggingOver)
    setDragState(null);
    setDraggedNode(null);
  };

  const editAsJSON = () => {
    let json = JSON.stringify(schema, null, 2);

    page.openModal(<PromptModal initialText={json} onSave={(edited) => {
      try {
        if (edited && edited !== json) {
          updateSchema(JSON.parse(edited));
        }
      } catch (err) {
        alert('Invalid JSON');
      } finally {
        page.closeModal();
      }
    }}/>, {title: 'Edit schema as JSON'});
  }

  const expandOperationPaths = () => {
    // First collapse everything
    toggleExpand('', true, true);
    
    // Then expand paths affected by operations
    setCollapsedNodes((nodes) => {
      const affectedPaths = new Set();
      
      // Collect all affected paths and their parent paths
      for (let i = 0; i < operationsHistory.length; i++) {
        const op = operationsHistory[i];
        let path = op.path;
        // For moves, include both source and destination paths
        if (op.fromPath) {
          path = op.fromPath;
          // Add destination path parts
          let parts = op.toPath.split('.');
          for (let j = parts.length; j > 0; j--) {
            affectedPaths.add(parts.join('.'));
            parts.pop();
          }
        }
        // Add source path parts
        let parts = path.split('.');
        for (let j = parts.length; j > 0; j--) {
          affectedPaths.add(parts.join('.'));
          parts.pop();
        }
      }

      // Expand affected nodes
      return {
        ...nodes,
        ...Object.fromEntries([...affectedPaths].map(path => [path, false]))
      };
    });
  };

  useImperativeHandle(ref, () => {
    return {
      getOperationsHistory() {
        return operationsHistory
      },
      applyOperations(ops) {
        applyOperations(ops);
      },
      expandOperationPaths() {
        expandOperationPaths();
      }
    };
  });

  
  return <div className="specs-schema-editor">
          <div className="bg-dark p-2 text-white d-flex justify-content-between sticky-top">
            <IconButton icon={'unfold_more'} className={''} onClick={() => toggleExpand('', true, false)}></IconButton>
            <IconButton icon={'unfold_less'} className={'mr-2'} onClick={() => toggleExpand('', true, true)}></IconButton>
            <IconButton icon={'difference'} className={'mr-2'} onClick={expandOperationPaths}></IconButton>

            <TextSearchInput value={search} onChange={(v) => {
              setSearch(v);
              toggleExpand('', true, true);
                setCollapsedNodes((nodes) => {
                  let matches = findMatchingPaths(schema, v);
                  _.each(nodes, ((v, path) => {
                    if(_.some(matches, m => m.startsWith(path) || path.startsWith(m))) {
                      nodes[path] = false;
                    }
                  }));
                  return { ... nodes }
                });
            }} placeholder="Search..." className="form-control form-control-sm"/>

            <IconButton icon={'edit'} className={'ml-2 no-wrap'} onClick={editAsJSON}>Edit&nbsp;as&nbsp;JSON</IconButton>
          </div>

          <div className="p-2">
            <SchemaTree
              schema={schema}
              searchText={search}
              updateSchema={updateOpsWithStateUpdate}
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
              handleDragOver={handleDragOver}
              handleDragEnd={handleDragEnd}
              dragState={dragState}
              collapsedNodes={collapsedNodes}
              changes={operationsHistory}
              toggleExpand={toggleExpand}
            />
          </div>
        </div>;
});


export function getCleanSchema(schema) {
  if(_.isPlainObject(schema)) {
    let allowedProps = schema.type ? validFieldsByType[schema.type] : Object.keys(schema);

    if(schema.type === 'objectTypedKeys') {
      // debugger;
    }
    let res = {};
    for(const prop of ['type', '_comments'].concat(allowedProps || ['properties']).sort()) {
      if(schema[prop]) {
        if(prop === 'properties') {
          res[prop] = _.mapValues(schema[prop], (value, key) => getCleanSchema(value));
        } else {
          res[prop] = getCleanSchema(schema[prop]);
        }
      }
    }
    return res;
  } else {
    return schema;
  }
}

/**
 * Finds all shortest paths in an object that match a given search text
 * @param {Object} obj - The object to search through
 * @param {string} searchText - The text to search for (case insensitive)
 * @returns {Array<string>} - Array of matching paths
 */
export function findMatchingPaths(obj, searchText) {
  // Blacklist of keys to ignore
  const blacklist = ["value", "values", "properties", "unit"];
  // Normalize search text to lowercase for case-insensitive matching
  const normalizedSearchText = searchText.toLowerCase();
  // Store matching paths
  const matchingPaths = [];

  // Recursive function to traverse the object
  function traverse(currentObj, currentPath, visitedPaths) {
    if (currentObj === null || typeof currentObj !== 'object') {
      return;
    }

    // Check if we're at a path that's a child of an already matched path
    const currentPathStr = currentPath.join('.');
    for (const visitedPath of visitedPaths) {
      if (currentPathStr.startsWith(visitedPath + '.')) {
        return; // Skip traversing children of already matched paths
      }
    }

    // Check if current path matches
    // if (currentPath.length > 0 && currentPathStr.toLowerCase().includes(normalizedSearchText)) {
    let lastKey = currentPath[currentPath.length - 1];
    if (currentPath.length > 0 && !blacklist.includes(lastKey) && lastKey.toLowerCase().includes(normalizedSearchText)) {
      matchingPaths.push(currentPathStr);
      visitedPaths.add(currentPathStr);
      return; // Stop traversing this branch
    }

    // Continue traversing
    for (const key in currentObj) {
      if(_.isString(currentObj[key]) && currentObj[key].toLowerCase().includes(normalizedSearchText)) {
        matchingPaths.push([...currentPath, key].join('.'));
        // visitedPaths.add([...currentPath, key].join('.'));
        continue;
      }

      // Recursively traverse
      traverse(currentObj[key], [...currentPath, key], visitedPaths);
    }
  }

  // Start traversal from root with empty path
  traverse(obj, [], new Set());

  return matchingPaths;
}


export default SpecsSchemaEditor;
