import React, { useState, useEffect, useRef } from 'react';

/**
 * EditableLabel - A simple component for inline text editing
 *
 * @param {Object} props
 * @param {string} props.value - The text value to display/edit
 * @param {function} props.onChange - Callback function when text changes
 * @param {string} [props.className] - Optional CSS class name
 * @param {Object} [props.style] - Optional inline styles
 * @param {boolean} [props.autoFocus] - Whether to auto focus the input
 * @param {function} [props.onBlur] - Callback function when the input loses focus
 * @param {string} [props.placeholder] - Placeholder text for the input
 */
const EditableLabel = ({ value, autoFocus, onChange, onBlur, placeholder, className = '', style = {} }) => {
  const [isEditing, setIsEditing] = useState(false);
  const spanRef = useRef(null);

  // Update the span's content when the value prop changes
  useEffect(() => {
    if (spanRef.current && !isEditing) {
      spanRef.current.textContent = value;
    }
  }, [value, isEditing]);

  useEffect(() => {
    if(autoFocus && spanRef.current) {
      spanRef.current.focus();
      window.getSelection().selectAllChildren(spanRef.current);
    }
  }, [spanRef, autoFocus]);

  const handleClick = () => setIsEditing(true);

  const handleBlur = (e) => {
    setIsEditing(false);
    if (onChange && spanRef.current.textContent !== value) {
      onChange(spanRef.current.textContent);
    }

    setTimeout(() => onBlur && onBlur(e), 1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      spanRef.current.blur();
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      spanRef.current.textContent = value;
      spanRef.current.blur();
    }
  };

  const handleKeyUp = (e) => {
    // if (onChange && spanRef.current.textContent !== value) {
    //   onChange(spanRef.current.textContent);
    // }
  }

  return (
    <span
      ref={spanRef}
      contentEditable={true}
      suppressContentEditableWarning={true}
      onClick={handleClick}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      // @ts-ignore
      autoFocus={autoFocus}
      placeholder={placeholder} // Hacked via CSS to work in admin-cerokm.less
      className={`editable-label ${isEditing ? 'editing' : ''} ${className}`}
      style={{
        display: 'inline-block',
        minWidth: '2em',
        padding: '0px 3px',
        border: isEditing ? '1px dashed #ccc' : '1px solid transparent',
        borderRadius: '3px',
        cursor: isEditing ? 'text' : 'text',
        ...style
      }}
    >
      {value}
    </span>
  );
};

export default EditableLabel;
