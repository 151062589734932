import React from 'react';
import { legoStateColors } from './LegoStateBadge';
import MultiButtonSwitch from '../common/editors/MultiButtonSwitch';

export function LegoStateSwitch({ value, onChange, compact = false, multi = false}) {
  if (!value) {
    value = 'published';
  }

  /** @type {Array<string|{value: string, label: string}>} */
  let options = ['published', 'unpublished', 'inprogress'];
  
  if(compact) {
    options = [
      {value: 'published', label: '✅'},
      {value: 'inprogress', label: '🚧'},
      {value: 'unpublished', label: '⛔'}
    ]
  }
  return <MultiButtonSwitch multi={multi} value={value} onChange={onChange} options={options} optionColorMap={legoStateColors}/>;
}
