import React, {Component} from 'react';

import SocketStatus from '../components/SocketStatus';

import _ from 'lodash';
import EventEmitter from 'events';
import { render } from 'react-dom';


export default class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      failed: false, connectionStatus: window.initialSocketStatus, initialEmail: props.initialEmail,
      connecting: false,
      email: props.initialEmail
    };
  }

  componentDidMount() {
    this.socketStatus = new SocketStatus(window.socket);

    this.socketStatus.onLifecyleEvent((eventName, params) => this.setState({ connectionStatus: eventName + ' ' + params }));

    this.socketStatus.on('connect', () => {
      this.setState({ connectionStatus: 'connected' });

      console.log('LOGIN PAGE: Socket connected');
      // If has access token in localStorage, try to use it
      if(localStorage['accessToken'] && !this.state.connecting) {
        this.setState({ connecting: true });

        console.log('Trying to autoconnect authenticate with localStorage token...');
        window.client.authenticate({ strategy: 'jwt', accessToken: localStorage['accessToken'] }).then(({ config }) => {
          try {
            let user = JSON.parse(localStorage['user']);
            if(this.props.onLogin) {
              this.props.onLogin(user, config);
            } else {
              console.log('CONECTADO! No onLogin prop, reloading page');
              window.location.reload();
            }
          } catch (e) {
            console.error('Unexpected error: Post authentication failed', e);
          } finally {
            this.setState({ connecting: false });
          }
        }).catch(err => console.error('Authentication with localStorage failed', err));
      }
    });

  }

  componentWillUnmount() {
    this.socketStatus.destroyHandlers();
  }

  login() {
    if (!this.state.pass) {
      return;
    }

    this.setState({ connecting: true });

    window.client.authenticate({
      strategy: 'local',
      email: this.state.email,
      password: this.state.pass
    }).then(({ accessToken, user, config }) => {
      console.log('Logged in, accessToken:', accessToken);
      // TODO: This is unsafe, a XSS attack can retrieve this token https://auth0.com/docs/security/store-tokens
      // TODO: Explore cookie stored token
      localStorage['accessToken'] = accessToken;
      localStorage['lastAccessEmail'] = this.state.email;
      localStorage['user'] = JSON.stringify(user);
      if(this.props.onLogin) {
        // this.setState({ connecting: false });
       this.props.onLogin(user, config);
      } else {
        window.location.reload();
      }
    }).catch(e => {
      // Show login page (potentially with `e.message`)
      this.setState({ connecting: false, failed: e.message });
      console.error('Authentication error', e);
    });
  }

  handlePasswordKeypress(event) {
    if (event.key == 'Enter') {
      this.login();
    }
  }

  render() {
    let { connectionStatus, connecting } = this.state;

    let online = connectionStatus === 'connected';
    let sizeClass = this.props.modal ? '' : 'jumbotron  min-vh-100'
    return <div className={`d-flex align-items-center justify-content-around ${sizeClass}`}>

      <div style={{width: '280px'}} className={'p-3 bg-light rounded bg-white border ' + (online ? '  border-success ' : ' border-warning translucent')}>
        <div className={'text-center mb-2'} style={{marginTop: '-25px'}}>
            {
              connectionStatus ?
                <span className={`badge badge-${online ? 'success' : 'warning'}`}>
                  {connectionStatus}
                </span> : null
            }
        </div>

        <div className={' mb-3 h3 text-center'}>Lego admin</div>

        <input type="email" name="email" autoFocus={!this.state.email} placeholder="Email" tabIndex="10" className={'form-control mb-2'}
               value={this.state.email} onChange={e => this.setState({ email: e.target.value })}/>

        <input type="password" name="password" autoFocus={this.state.email} placeholder={localStorage['accessToken'] ? '●●●●●●●●' : 'Password'} tabIndex="11"
               className={'form-control mb-2'}
               onChange={(e) => this.setState({ pass: e.target.value })}
               onKeyPress={this.handlePasswordKeypress.bind(this)}
        />

        <button className={'btn btn-block' + (this.state.pass ? ' btn-primary' : ' btn-secondary disabled')}
                onClick={() => this.login()}>Login
        </button>

        {this.state.failed ?
          <div className={'alert alert-danger small p-1 mt-2'}>Login failed. Check credentials. {this.state.failed}</div> : null}
      </div>
    </div>;
  }
}
