import React from 'react';

export default function LeftRightLayout({ children, top = false, className = '', ...other }) {
  const childrenArr = React.Children.toArray(children);

  if (!childrenArr) {
    return <div className={'alert alert-danger'}>LeftRightLayout has to be used with extactly 2 children.</div>;
  }

  let [left, right] = childrenArr;

  return <div className={`d-flex justify-content-between align-items-${top ? 'top' : 'center'} ${className}`} {...other}>
    {left}
    {right}
  </div>;
}
