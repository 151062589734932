import React, { useState, useEffect } from 'react';
import { Async } from 'react-select';
import _ from 'lodash';

import { getFlexibleRegex } from '../common/editors/autocomplete-filter-logic';
import useModelsData from './data-caches/useModelsData';

let SPECS = {
  'fuel': ['diesel', 'nafta', 'electric'],
  'body': ['sedan', 'hatchback', 'pickup', 'coupecabriolet', 'familiar'],
  'engine': [''],
  'country': ['ar', 'mx', 'es', 'cl', 'co', 'us'],
}

let SPECIAL_TOKENS = {
  _source: ['inferred_from_pictures'],
}

let FIELD_COLORS_MAPPING = {
  'modelId': 'violeta',
  'genId': 'success',
  'make': 'violeta',
  'year': 'primary',
  'trim': 'turquesa',
  'trims': 'turquesa',
  '_source': 'partial-success'
}

export default function ModelContextEditor({ value, onChange, ...otherProps }) {
  const data = useModelsData();

  const handleSelectChange = (values, actionMeta) => {
    let mergedContext = {};
    let trimsArr = [];
    _.each(values, ({ value: { trims, ...otherValues } }) => {
      if (trims) {
        trimsArr.push(trims);
      }
      mergedContext = { ...mergedContext, ...otherValues };
    });

    if (trimsArr.length) {
      mergedContext.trims = trimsArr;
    }

    if (mergedContext.modelId && mergedContext.make) {
      if (value.make) {
        delete mergedContext['make'];
      } else {
        delete mergedContext['modelId'];
      }
    }

    onChange(mergedContext);
  };

  const getOption = (props) => {
    let {innerProps, isSelected, isFocused, data} = props;
    let {innerRef, ... filteredProps} = innerProps;

    let [[field, val]] = _.toPairs(data.value)
    let color = FIELD_COLORS_MAPPING[field] || 'dark'

    return <div {... filteredProps} className={'p-1 '+((isSelected || isFocused) ? 'bg-light-primary': '')}>
      <span className={`badge badge-${color}`}>{props.label}</span>
    </div>
  };

  const getMultiValueLabel = (props) => {
    let [[field, val]] = props.data.value ? _.toPairs(props.data.value) : _.toPairs(props.data);

    let color = FIELD_COLORS_MAPPING[field] || 'dark'
    return <span key={field + val} className={`badge badge-${color}`}>{props.data.label}</span>
  };

  let makes = (data.makes || []).map(m => ({label: m, value: {make: m}}))
  let models = (data.models || []).map(m => ({ label: m.replace('-', ' '), value: { modelId: m } }))
  let generations = (data.generations || []).map(({genId, name}) => ({label: name ? `${name} (${genId})` : genId, value: {genId}}));

  let years = _.range(1980, 2026).map(y => ({label: y.toString(), value: {year: y}}))

  let specs = _.flatten(_.map({...SPECS, ...SPECIAL_TOKENS}, (vals,key) => _.map(vals, val => ({label: `${key}=${val}`, value: {[key]: val}}))))

  const options = [...makes, ...years, ...models, ...generations, ...specs];

  let { make, modelId, genId, year, trim, trims, ...other } = value || {};

  let values = [];
  if(make)
    values.push({label: make, value: {make}})

  if(modelId)
    values.push({label: modelId.replace("-", " "), value: {modelId}})

  if(genId) {
    values.push(_.find(generations, v => v.value?.genId == genId) || { label: genId, value: { genId } })
  }

  if(year)
    values.push({label: year, value: {year}})

  if(trims) {
    for (const t of trims) {
      values.push({ label: t, value: { trims: t } })
    }
  }

  _.each(other, (val, key) => values.push({label: key+'='+val, value: {[key]: val}}))

  let loadOptions = async (input) => {
    let regex, flexibleRegex;

    try {
      regex = new RegExp((input || "").trim(), 'i');
      flexibleRegex = getFlexibleRegex((input || "").trim());
    } catch(e) {
      regex = new RegExp(_.escapeRegExp(input));
      flexibleRegex = regex;
    }

    let extra = [];
    let matchSpec = input.match(/^([^=\s]+)=([^=]*[^=\s])$/);
    if(input && matchSpec) {
      let [,key, val] = matchSpec;
      extra = [{label: input, value: {[key]: val}}]
    }

    let matches = options.map((op) => [op, op.label.match(regex) || op.label.match(flexibleRegex)]).filter(m => m[1]);

    let sortedMatches = _.sortBy(matches, ([op, matches]) => {
      let includesModelBoost = modelId && op.label.includes(modelId) ? -3 : 0;
      let matchLength = matches[0].length;
      let resLength = op.label.length;
      return includesModelBoost + matchLength + resLength/100;
    }).map(m => m[0]);

    return [...extra, ...sortedMatches.slice(0,20)]
  };

  return (
    <Async
      closeOnSelect={true}
      isMulti
      components={{
        Option: getOption,
        MultiValueLabel: getMultiValueLabel,
      }}
      onKeyDown={(e) => {
        // Hack to make shift+Home work and select the text
        if (e.key === 'Home' && e.shiftKey) {
          e.defaultPrevented = true;
        }
      }}
      getOptionValue={(option) => JSON.stringify(option)}
      onChange={handleSelectChange}
      loadOptions={loadOptions}
      placeholder="Write make, model or year..."
      menuShouldScrollIntoView={false}
      maxMenuHeight={170}
      styles={{ menu: (provided) => ({...provided, zIndex: 1000})}}
      value={values}
      {...otherProps}
    />
  );
}
