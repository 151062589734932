export default [
  { to: '/legos/all', display: 'All' },
  { to: '/legos/specs', display: 'Specs', contributorsAllowed: true },
  { to: '/legos/fusebox', display: 'Fusebox', contributorsAllowed: true },
  { to: '/legos/articles', display: 'Articles'},
  { to: '/legos/definitions', display: 'Definitions'},
  { to: '/legos/data-feedback', display: 'User feedback', contributorsAllowed: true},
  { to: '/legos/contributions', display: 'Contributions'},
  { to: '/legos/data-issues', display: 'Data Issues', contributorsAllowed: true},
  { to: '/legos/image-clusters', display: 'Img Clusters', contributorsAllowed: true},
  { to: '/legos/other', display: 'Other legos', right: true, contributorsAllowed: true },
  { to: '/legos/deleted', display: 'Lego trash', right: true}
];
