import LegoAdminPageContext from '../../legoAdminPageContext';
import MongoDocEditor from '../../../components/lego/MongoDocEditor';
import _ from 'lodash';
import { IconButton } from '../../../components/common/IconButton';
import TextSearchInput from '../../../components/common/TextSearchInput';
import { UserAvatar } from '../../../components/common/UserAvatar';
import { UserAvatarLive } from '../../../components/common/UserAvatarLive';
import BadgeId from '../../../components/common/BadgeId';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { fromNow } from '../../../components/common/TimeUtils';


/** @type {import('react').ForwardRefExoticComponent<any & {ref: import('react').Ref<{getOperationsHistory: function(): Array, applyOperations: function(Array): void}>}>} */
const LiveTableComponent = forwardRef(function LiveTable ({ service, columns, forceRefresh, selectionActions, hideId, filter, limit = 100, onTextSearch }, ref) {
  const { page } = useContext(LegoAdminPageContext);
  const [objects, setObjects] = useState(null);
  const [textFilter, setTextFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  function refresh(extraFilter = {}) {
    setLoading(true);
    service.find({query: {$limit: limit, ... filter, ... extraFilter}}).then(setObjects).finally(() => setLoading(false));
  }

  function refreshIds(ids, extraFilter = {}) {
    let idsFilter = { _id: { $in: ids } };
    setLoading(true);
    service.find({query: {$limit: limit, ... idsFilter, ... extraFilter}}).then((updatedObjects) => {
      setObjects(objs => {
        const byId = _.keyBy(updatedObjects, '_id')
        // Remove missing objects
        _.each(ids, id => byId[id] = byId[id] || false);
        return _.compact(_.map(objs, o => byId[o._id] ?? o));
      });
    }).finally(() => setLoading(false));
  }

  useEffect(() => {
    refresh();
  }, [JSON.stringify(filter), limit]);

  // Exporse methods as ref
  useImperativeHandle(ref, () => {
    return {
      refresh() {
        refresh();
      },
      refreshIds(ids, extraFilter) {
        refreshIds(ids, extraFilter);
      }
    };
  });

  async function openEdit(obj) {
    let freshObj = obj;
    if (freshObj && freshObj._id) {
      // freshObj = await this.refreshObject(obj);
      // this.setUrlParams({ editing: freshObj._id });
    }

    const defaultOnSaveCallback = async (changedObj, closeDialog) => {
      try {
        // let res = await this.updateOrCreate(freshObj, changedObj);
        //
        // if (closeDialog) {
        //   this.modalActionsBus.emit('close');
        // } else if (freshObj._id) {
        //   return await this.refreshObject(freshObj);
        // }
        // return res;
      } catch (err) {
        this.handleError(err);
      }
    };

    page.openModal(<div>
      <MongoDocEditor obj={obj} onSave={defaultOnSaveCallback} onCancel={() => page.closeModal()}/>
    </div>, {
      fullScreen: false,
      onClose: () => page.deleteUrlParam('editing'),
      title: `${obj?._id ? 'Edit' : 'Create'} document`
    });
  }


  if (objects) {
    let filteredObjects = objects;
    if (textFilter) {
      filteredObjects = _.filter(objects, o => _.includes(JSON.stringify(o).toLowerCase(), textFilter.toLowerCase()));
    }

    const selectNone = () => setSelected([]);

    const selectAll = () => setSelected(_.map(filteredObjects, '_id'));

    const unselectRow = (obj) => setSelected(_.without(selected, obj._id));

    const selectRow = (obj, clickEvent) => {
      let selection = [...selected];
      let filteredIndexes = filteredObjects.map(o => o._id);
      if (clickEvent && clickEvent.shiftKey) {
        let indices = selection.filter(s => filteredIndexes.includes(s)).map(row => filteredIndexes.indexOf(row));
        let min = _.min(indices);
        let max = _.max(indices);
        let r = filteredIndexes.indexOf(obj._id);
        if (r < min) {
          selection = filteredIndexes.slice(r, max + 1);
        } else {
          selection = filteredIndexes.slice(min, r + 1);
        }
      } else {
        selection = _.uniq([...selected, obj._id]);
      }
      setSelected(selection);
    };

    let nColumns = _.toPairs(columns).length + 1 + (hideId ? 0 : 1);

    let selectionButton = <IconButton icon={'check_box_outline_blank'} level={'secondary'} onClick={selectAll}/>;
    if (selected.length > 0) {
      if (selected.length === (objects || []).length) {
        selectionButton = <IconButton icon={'check_box'} level={'primary'} onClick={selectNone}/>;
      } else {
        selectionButton = <IconButton icon={'indeterminate_check_box'} level={'primary'} onClick={selectNone}/>;
      }
    }

    let onFireSearch = () => {
      if(textFilter) {
        onTextSearch(textFilter);
        setTextFilter('');
      }
    };

    return <div className={'border'}>
      <table className={'table table-sm table-striped ' + (loading ? 'translucent' : '')}>
        <thead>
        <tr className={'sticky-top bg-light'}>
          <th className={'p-0 '}>
            {selectionButton}
          </th>
          <th colSpan={nColumns} className={'text-info'}>
            <div className={'d-flex align-items-center'}>

              <TextSearchInput className={'form-control-sm mr-2'} style={{ maxWidth: '500px' }} value={textFilter}
                               onChange={setTextFilter} onEnter={onFireSearch} placeholder={'Filter rows...'}/>

              {textFilter && onTextSearch ? <IconButton icon={'filter_alt'} onClick={onFireSearch}/> : null}

              {textFilter ? filteredObjects.length + '/' : ''}{objects.length} rows


              {selected.length ? <div className={'ml-5 text-dark d-flex align-items-center'}>
                {selected.length} selected

                {selectionActions && selectionActions(_.filter(objects, o => selected.includes(o._id)))}
              </div> : null}
            </div>
          </th>
        </tr>

        <tr>
          <th>{}</th>
          {hideId ? null : <th>Id</th>}
          {_.map(columns, (v, k) => <th key={k}>{k}</th>)}
        </tr>
        </thead>
        <tbody>

        {_.map(filteredObjects, o => {
          let { _id, createdBy, updatedBy, createdAt, updatedAt } = o;
          updatedBy = updatedBy || createdBy;
          updatedAt = updatedAt || createdAt;

          let isSelected = _.includes(selected, _id);
          let selectButton = null;
          if (isSelected) {
            selectButton = <IconButton icon={'check_box'} level={'primary'} onClick={() => unselectRow(o)}/>;
          } else {
            selectButton =
              <IconButton icon={'check_box_outline_blank'} level={'secondary'} onClick={(e) => selectRow(o, e)}/>;
          }

          return <tr key={o._id} className={isSelected ? 'row-highlight' : ''}>
            <td className={'p-0 ' + (selected.length ? '' : 'parent-hover-transparent')}>
              {selectButton}
            </td>

            {hideId ? null : <td className={'no-wrap'}>
              {updatedBy ? ((updatedBy?.userId) ? <UserAvatar user={updatedBy}/> :
                <UserAvatarLive id={updatedBy?.id || updatedBy}/>) : null}
              <span className={'d-inline-block ml-2 align-middle'}>
                <span className={'text-info small'}>{updatedAt ? fromNow(updatedAt) : ''}</span>
                <br/>
                <BadgeId id={_id}/>
              </span>
            </td>}

            {_.map(columns, (v, k) => {
              let cellValue = v(o);
              if( cellValue?.type === 'td') {
                return cellValue;
              } else {
                return <td key={k}>{cellValue}</td>;
              }
            })}
          </tr>;
        })}

        { onTextSearch && textFilter ? <tr>
          <td colSpan={nColumns} className={'text-center p-1 bg-light-primary'}>
            <IconButton icon={'filter_alt'} onClick={() => setTextFilter('') + onTextSearch(textFilter)}>
              Use text filter "{textFilter}" in database query for more results
            </IconButton>
          </td>
        </tr> : null }
        </tbody>
      </table>
    </div>;
  } else {
    return <div className={'p-3'}>Loading...</div>;
  }
});

export default LiveTableComponent;
