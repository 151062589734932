import React from 'react';
import _ from 'lodash';

export default function MultiButtonSwitch({ label = null, value, onChange, multi = false, options, optionColorMap = null, defaultMulti = false, nullable = false }) {

  return <div className="zoom-90 btn-group btn-group-sm btn-group-toggle border rounded" data-toggle="buttons">
    {
      label ? <label className={`btn pb-0 btn-${value?.length ?? value ? 'primary' : 'light'}`}>{label}</label> : null
    }
    {
      options.map(s => {
        let optionValue = s?.value || s;
        let optionLabel = s?.label || s;

        let onClick, isActive;

        if (multi) {
          isActive = _.find(value || [], o => _.isEqual(o, optionValue));
          onClick = (e) => {
            if (isActive) {
              onChange(value.filter(o => !_.isEqual(o, optionValue)));
            } else if(e.ctrlKey || e.shiftKey || defaultMulti) {
              onChange([...value, optionValue]);
            } else {
              onChange([optionValue]);
            }
          }
        } else {
          isActive =  _.isEqual(value, optionValue);
          onClick = () => onChange(isActive && nullable ? null : optionValue, !isActive);
        }

        let color = (optionColorMap || {})[optionValue] || 'primary';

        return <label key={optionValue+optionLabel} className={`btn ${isActive ? `active btn-${color}` : 'btn-light'}`}>
          <input type="radio" name="options" autoComplete="off" onClick={onClick} readOnly={true} checked/> <span title={optionValue}>{optionLabel}</span>
        </label>;
      })
    }
  </div>;
}
