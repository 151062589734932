import { SpecPathValue } from './SpecPathValue';
import _ from 'lodash';

export function FactoidSpecs({ factoid, skipPaths = [] }) {
  if(!factoid) {
    return null;
  }

  let {derivedSpecs, missingInSchema} = factoid;

  if (!derivedSpecs && !missingInSchema) {
    return <span className={'p-1'}><span className={'badge badge-secondary'}>No specs data</span></span>;
  } else if(_.isArray(derivedSpecs) || _.isArray(missingInSchema)) {
    let specsViz, missingSpecsViz;

    if(derivedSpecs?.length) {
      if(skipPaths?.length) {
        derivedSpecs = _.filter(derivedSpecs, p => !skipPaths.includes(p[0]));
      }

      specsViz = _.map(derivedSpecs, ([path, value]) => <div className={'ml-2'} key={path} >
        <SpecPathValue path={path} value={value}/>
      </div>);
    }

    if(missingInSchema?.length) {
      if(skipPaths?.length) {
        missingInSchema = _.filter(missingInSchema, p => !skipPaths.includes(p[0]));
      }
      missingSpecsViz = <div className={'bg-light-danger p-1 rounded'}>
        Missing:
        {_.map(missingInSchema, ([path, value]) => <div className={'ml-2'} key={path} >
          <SpecPathValue path={path} value={value}/>
        </div>)}
      </div>
    }

    return <div>
      {specsViz}
      {missingSpecsViz}
    </div>
  } else {
    return <pre className={'ml-2 small break-word-all'} key={factoid._id}>{JSON.stringify({derivedSpecs, missingInSchema}, null, 2)}</pre>;
  }
}
