import React from 'react';
import _ from 'lodash';
import LivePage from '../LivePage';
import LegoAdminPageContext from '../legoAdminPageContext';
import LegoContextSummary from '../../components/lego/LegoContextSummary';
import BadgeId from '../../components/common/BadgeId';
import { IconButton } from '../../components/common/IconButton';
import submenuCerokm from '../menus/submenu-cerokm';
import LiveTable  from './components/LiveTable';
import SpecsGroupSelector from './spec-schema-editor/SpecsGroupSelector';
import ModelContextEditor from '../../components/lego/ModelContextEditor';
import SingleValueSelector from '../../components/common/editors/SingleValueSelector';
import SpecsSchemaChangePreview from './components/SpecsSchemaChangePreview';
import { SpecPathValue } from './components/SpecPathValue';
import AskAI from '../../components/ai/AskAI';
import { FactoidSpecs } from './components/FactoidSpecs';
import AIModelBadge from '../../components/ai/AIModelBadge';
import { SwitchInput } from '../../components/common/SwitchInput';
import { Icon } from '../../components/common/Icon';
import { sortObjectKeysDeep } from './spec-schema-editor/SpecSchemaEditor';

class CeroKmFragmentsManager extends LivePage {
  constructor(props) {
    super(props);
    this.svcSource = this.service('services/cerokm/source');
    this.svcFragments = this.service('services/cerokm/sourcefragment');
    this.svcFactoids = this.service('services/cerokm/sourcefactoid');
    this.svcSchemaFixes = this.service('services/cerokm/schemafix');
    this.svcAi = this.service('services/cerokm/ai/process-fragments');
    this.submenu = submenuCerokm;

    let filters = this.getUrlParam('filters', true);
    if(filters) {
      this.state.filters = filters;
      console.log("Loaded filters from URL", filters)
    } else {
      this.state.filters = {context: {modelId: 'Toyota-Tacoma'}};
    }

    this.tableRef = React.createRef();
  }
  // componentDidMount() {
  //   super.componentDidMount();
  //
  //   this.openModal(<AskAI prompt={'List the provinces of Argentina and their capital'}/> )
  // }

  filterByText(text) {
    if(text) {
      this.updateFilters({... this.state.filters, text: {$regex: text, $options: 'i'}});
    } else {
      delete this.state.filters.text;
      this.updateFilters(this.state.filters);
    }
  }

  filterByGroup(specsGroup) {
    let filters = { ...this.state.filters };

    if (specsGroup) {
      filters.specsGroupIds = specsGroup._id;
    } else {
      delete filters.specsGroupIds;
    }

    this.updateFilters(filters);
  }

  filterByNoFactoids(noFactoids) {
    this.updateFilters({ ... this.state.filters, noFactoids });
  }

  filterByNoSpecs(noSpecs) {
    this.updateFilters({ ... this.state.filters, noSpecs});
  }


  filterByContext(context) {
    let filters = { ...this.state.filters };

    if (_.isEmpty(context)) {
      delete filters.context;
    } else {
      filters.context = context;
    }

    this.updateFilters(filters);
  }

  updateFilters(filters) {
    this.setState({ filters });
    if (_.isEmpty(filters)) {
      this.deleteUrlParam('filters');
    } else {
      this.setUrlParam('filters', filters);
    }
  }

  acceptFactoids(factoids) {
    const ids = _.map(factoids, f => f._id);
    const sourceFragmentId = factoids[0].sourceFragmentId;

    this.runAsync((async () => {
      let sameFragmentWithoutManualRevision = {_id: {$nin: ids}, sourceFragmentId, manualRevision: {$exists: false}};

      await Promise.all([
        this.svcFactoids.update({query: { _id: {$in: ids} }}, {$set: {manualRevision: 'correct' }}),
        this.svcFactoids.update({query: sameFragmentWithoutManualRevision}, {$set: {manualRevision: 'incorrect', isObsolete: true, obsoleteReason: 'Revision' }}),
        ]);
      this.tableRef.current.refreshIds([sourceFragmentId], {$populate: true});
    })())
  }

  rejectFactoids(factoids) {
    const ids = _.map(factoids, f => f._id);
    const sourceFragmentId = factoids[0].sourceFragmentId;

    this.runAsync((async () => {
      await  this.svcFactoids.update({query: { _id: {$in: ids} }}, {$set: {manualRevision: 'incorrect', isObsolete: true, obsoleteReason: 'Revision'}}),
      this.tableRef.current.refreshIds([sourceFragmentId], {$populate: true});
    })())
  }

  deleteFactoids(fragmentIds) {
    let filter = {
      sourceFragmentId: { $in: fragmentIds },
    };
    this.runAsync(this.svcFactoids.remove({query: filter}).then(() => this.tableRef.current.refreshIds(fragmentIds)));
  }

  seeFixes(fragment) {
    this.runAsync(this.svcSchemaFixes.find({query: {_id: {$in: fragment.schemaFixes}}}).then((res) => {
      res = res[0];
      if(res) {
        this.openModal(<SpecsSchemaChangePreview changes={res.fixOperations} usage={res.fixUsage}/>, {title: 'Results'});
      } else {
        alert('No fixes found');
      }
    }));
  }

  renderPageBody() {
    const {filters, highlightedGroups} = this.state;

    let context = filters?.context;
    let hasGroupFilter = filters?.specsGroupIds;

    const fragmentsTableColumns = {
      Text: o => {
        
        let obsoleteFactoids = _.filter(o.factoids, f => f.isObsolete);
        let validFactoids = _.filter(o.factoids, f => !f.isObsolete);
        
        let factoidsByDerivedSpecs = _.groupBy(validFactoids, factoidToString);
        
        let commonSpecs;
        let status = 'noFactoids';
        if (validFactoids.length) {
          if (_.some(validFactoids, f => f.manualRevision === 'correct')) {
            status = 'okRevision'
          } else if (_.every(validFactoids, f => f.isObsolete)) {
            status = 'obsolete'
          } else if (validFactoids.length > 1) {
            const commonSpecsPairs = _.intersectionBy(... _.map(factoidsByDerivedSpecs, group => group[0].derivedSpecs), pair => JSON.stringify(pair));
            commonSpecs = _.map(commonSpecsPairs, pair => pair[0]);

            if (_.keys(factoidsByDerivedSpecs).length > 1) {
              status = 'conflict';
            } else {
              if (factoidsByDerivedSpecs['false']) {
                status = 'noUsefulData';
              } else {
                status = 'okMultiple';
                commonSpecs = [];
              }
            }
          } else if (validFactoids[0].missingInSchema?.length) {
            status = 'conflict';
          } else if (validFactoids[0].derivedSpecs?.length) {
            status = 'okSingle';
          } else {
            status = 'noSpecs';
          }
        }

        let statusColors = {
          okMultiple: '#c3ffcb',
          okRevision: 'white',
          okSingle: '#efffd5',
          conflict: '#ffebc1',
          noUsefulData: '#bbb',
          noSpecs: '#ffbdbd',
          noFactoids: 'transparent',
          obsolete: '#AAA',
        }

        return <div>
          <div className={'row no-gutters'}>
            <div className={'col-6 small'}>{o.text}</div>

            <div className={'col-6 border-left p-1 '+ (status === 'obsolete' ? 'translucent' : '')} style={{backgroundColor: statusColors[status]}} >
              {obsoleteFactoids.length ? <div className={'badge badge-secondary rounded p-1'}>
                {obsoleteFactoids.length} obsolete factoids
              </div> : null}

              {commonSpecs?.length ? <div className={'bg-white rounded p-1'}>
                All:
                <FactoidSpecs factoid={{derivedSpecs: _.filter(validFactoids[0].derivedSpecs, p => commonSpecs.includes(p[0]))}}/>
              </div> : null}

              {_.map(factoidsByDerivedSpecs, (fs, specs) => {
                let groupIsCorrect = _.some(fs, f => f.manualRevision === 'correct');
                let groupIsIncorrect = _.some(fs, f => f.manualRevision === 'incorrect');

                return <div key={specs} className={`py-1 ${groupIsIncorrect ? 'translucent bg-light-secondary' : (groupIsCorrect ? 'bg-light-success' : '')}`}
                            style={(groupIsCorrect ? {background: statusColors.okMultiple} : {})}
                >
                  <div className={'d-flex justify-content-between'}>
                    <div>
                      <div>
                      {
                        _.map(_.sortBy(fs, 'schemaVersion'), (f, j) => <span key={f._id} className={'small mr-1'}>
                            <AIModelBadge model={f.derivedBy}/>

                          {fs[j + 1]?.schemaVersion !== f.schemaVersion ?
                            <span title={`Schema: ${f.schemaVersion}. Prompt: ${f.promptVersion}`}
                                  className={'badge badge-secondary mr-3'}> {f.schemaVersion} </span> :
                            null}
                        </span>
                      )}
                        {groupIsCorrect ? <span className={'badge badge-success'}>MARKED CORRECT</span> : null}
                        {groupIsIncorrect ? <span className={'badge badge-danger'}>INCORRECT</span> : null}
                      </div>

                      <FactoidSpecs factoid={fs[0]} skipPaths={commonSpecs}/>
                    </div>

                    <div className={`align-self-center mr-1 ${status !== 'conflict' ? 'parent-hover-transparent': ''}`}>
                      { !groupIsCorrect ? <IconButton icon={'check'} level={'success'} className={'p-0'}
                                  onClick={() => this.acceptFactoids(fs)}/> : null }
                      <br/>
                      { !groupIsIncorrect ? <IconButton icon={'close'} level={'danger'} className={'p-0'}
                                  onClick={() => this.rejectFactoids(fs)}/> : null }
                    </div>
                  </div>
                </div>;
              })}
            </div>
          </div>
        </div>;
      },
      // Factoids: o => <div className={'row'}><pre>{JSON.stringify(o.factoids, null, 2)}</pre></div>,
      fixes: o => o.schemaFixes?.length ? <span className={'btn btn-sm btn-success'} 
      onClick={() => this.seeFixes(o)}>
        <Icon  icon={'auto_fix_high'}/> See {o.schemaFixes.length} fix proposals
      </span> : null,

      "Specs groups": o => <span className={'badge badge-info'} onMouseEnter={() => this.setState({highlightedGroups: o.specsGroupIds})} onMouseLeave={() => this.setState({highlightedGroups: null})}>
        {o.specsGroupIds.length}
      </span>,

      sourceId: o => <BadgeId id={o.sourceId}/>
    };

    const processFragments = async (objs) => {
      this.runAsync(this.svcAi.create({fragmentIds: _.map(objs, '_id'), model: this.state.model}).then((res) => {
        this.tableRef.current.refreshIds(_.map(objs, '_id'),{$populate: true});
      }));
    }

    const fixFragments = async (objs) => {
      this.runAsync(this.svcAi.find({query: {fragmentIds: _.map(objs, '_id'), model: this.state.model}}).then((res) => {
        console.log(res);
        this.openModal(<SpecsSchemaChangePreview changes={res.fixOperations} usage={res.fixUsage}/>, {title: 'Results'});
      }));
    }

    const renderActions = (objs) => <span className={'ml-5 text-dark'}>
      <IconButton level={'success'} icon={'auto_mode'} onClick={() => processFragments(objs)}>Process specs</IconButton>

      <span className={'d-inline-flex'} style={{width: '300px'}}>
        <IconButton level={'primary'} icon={'auto_fix_high'} onClick={() => fixFragments(objs)}>Fix schema</IconButton>
        <SingleValueSelector className={'flex-grow-1 zoom-75'} value={this.state.model} onChange={(v) => this.setState({model: v})} options={['claude-3-7-sonnet-20250219', 'gpt-4o', 'gemini-2.0-flash-exp', 'gemini-2.5-pro-exp-03-25']}/>
      </span>

      <IconButton level={'danger'} icon={'delete'} onClick={() => this.deleteFactoids(objs.map(o => o._id))}></IconButton>
    </span>

    let fragmentFilter = _.omit(this.state.filters || {}, 'context');
    if (!_.isEmpty(fragmentFilter)) {
      fragmentFilter.$limit = 300;
    }
    fragmentFilter.$populate = true;
    // fragmentFilter.noFactoids = false;
    // fragmentFilter.noSpecs = false;

    const textFilter = this.state.filters?.text?.$regex;

    // throw new Error('test');
    return <div className={'px-3'}>
      {/*<h5>*/}
      {/*  Sources*/}

      {/*</h5>*/}
      {/*<LiveTable service={this.svcSource}/>*/}


      <div className={'mt-3 mb-2 d-flex align-items-center'}>
        <h5 className={'mr-3'}>
          Specs Groups

        </h5>

        <div style={{width: '350px'}}>
          <ModelContextEditor value={context} onChange={(context) => this.filterByContext(context)}/>
        </div>
      </div>

      <SpecsGroupSelector highlightedIds={highlightedGroups} initialSelection={filters?.specsGroupIds} context={context} onGroupSelected={(group) => this.filterByGroup(group)}/>

      <h5 className={'mt-3'}>
        Fragments
          <IconButton icon={'sync'} level={'primary'} className={'ml-2'} onClick={() => this.tableRef.current.refresh()}/>
      </h5>

      <div className={'d-flex h6 align'} style={{gap: '20px'}}>
        <SwitchInput triState={true} value={fragmentFilter?.noFactoids} onChange={v => this.filterByNoFactoids(v)}>
          Without factoids?
        </SwitchInput>

        <SwitchInput triState={true} value={fragmentFilter?.noSpecs} onChange={v => this.filterByNoSpecs(v)}>
          Missing specs?
        </SwitchInput>

        {hasGroupFilter ? <span className={'badge badge-light'}>
          specsGroupId=<BadgeId id={filters.specsGroupIds}/>
           <IconButton icon={'clear'} size={'sm'} level={'danger'} onClick={() => this.filterByGroup(null)}/>
        </span> : null}

        {textFilter ? <span className={'badge badge-light'}>
           text=<span className={'badge badge-primary'}>{textFilter}</span>
           <IconButton icon={'clear'} size={'sm'} level={'danger'} onClick={() => this.filterByText(null)}/>
         </span> : null}
      </div>

      <LiveTable service={this.svcFragments} columns={fragmentsTableColumns} selectionActions={renderActions}
                 ref={this.tableRef} filter={fragmentFilter} onTextSearch={(text) => this.filterByText(text)}/>
    </div>;
  }
}

CeroKmFragmentsManager.contextType = LegoAdminPageContext;

function factoidToString(factoid) {
  let {derivedSpecs, missingInSchema, isObsolete} = factoid;
  if(derivedSpecs || missingInSchema || isObsolete) {
    if(_.isEmpty(derivedSpecs)) {
      derivedSpecs = null;
    }
    if(_.isEmpty(missingInSchema)) {
      missingInSchema = null;
    }
    return JSON.stringify([sortObjectKeysDeep(derivedSpecs), sortObjectKeysDeep(missingInSchema), isObsolete]);
  } else {
    return factoid.derivedSpecs;
  }
}

// For development
function FuncComponent(props) {
  return <CeroKmFragmentsManager {...props}/>;
}

export default FuncComponent;
