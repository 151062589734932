let iconsForSemantic = {
  "AIRBAG": "AIRBAG",

  "DOBLE_TRACCION": "4X4",
  "MODULO_DOBLE_TRACCION": "4X4",

  "AIRE_ACONDICIONADO": "AIRE_ACONDICIONADO",
  "CLUTCH_AIRE_ACONDICIONADO": "AIRE_ACONDICIONADO",

  // "ALARMA_ANTIRROBO": "ALARMA_ANTIRROBO",

  // "ALTERNADOR": "ALTERNADOR",

  "ARRANQUE": "ARRANQUE",
  "BURRO_DE_ARRANQUE": "ARRANQUE",

  "ASIENTO_ELECTRICO": "ASIENTO_ELECTRICO",
  "ASIENTO": "ASIENTO_ELECTRICO",
  "COMANDO_AJUSTE_ASIENTO": "ASIENTO_ELECTRICO",

  "BOCINA": "BOCINA",

  "BOMBA_DE_COMBUSTIBLE": "BOMBA_DE_COMBUSTIBLE",
  "BOMBA_DE_COMBUSTIBLE(fluid=NAFTA)": "BOMBA_DE_COMBUSTIBLE",
  "BOMBA(fluid=COMBUSTIBLE)": "BOMBA_DE_COMBUSTIBLE",

  "CALEFACCION": "CALEFACCION",

  "CALEFACCION_DE_ASIENTO": "CALEFACCION_DE_ASIENTO",
  "CALEFACCION_DE_ASIENTO(of=ASIENTO(ubicacion=PARTE_DELANTERA))": "CALEFACCION_DE_ASIENTO",

  "ASIENTO_TERMICO": "CALEFACCION_DE_ASIENTO",

  "CIERRE_CENTRALIZADO": "CIERRE_CENTRALIZADO",

  "COMPRESOR_DE_AC": "COMPRESOR_DE_AC",

  "CONECTOR_OBD": "CONECTOR_OBD",
  "SISTEMA_OBD": "CONECTOR_OBD",

  "CONECTOR_USB": "CONECTOR_USB",

  "CRUISE_CONTROL": "CRUISE_CONTROL",

  "DESEMPANADOR": "DESEMPANADOR",

  "DESEMPANADOR_TRASERO": "DESEMPANADOR_TRASERO",

  // "DIRECCION_HIDRAULICA": "DIRECCION_HIDRAULICA",

  "ECU": "ECU",

  "ENCENDEDOR": "ENCENDEDOR",

  "ESPEJO_RETROVISOR_ELECTRICO_ANTIDESLUMBRANTE": "ESPEJO",
  "ESPEJO": "ESPEJO",
  "ESPEJO_ELECTRICO": "ESPEJO",
  "ESPEJO_LATERAL": "ESPEJO",


  "ELECTROVENTILADOR": "VENTILADOR_RADIADOR",

  "ESTEREO": "ESTEREO",
  "SUBWOOFER": "ESTEREO",
  "AMPLIFICADOR": "ESTEREO",

  // "INYECTOR": "INYECTOR",

  "LEVANTAVIDRIOS": "LEVANTAVIDRIOS",
  "VENTANILLA": "LEVANTAVIDRIOS",

  "LIMPIA_PARABRISAS": "LIMPIA_PARABRISAS",

  "LIMPIAPARABRISAS_AUTOMATICO": "LIMPIAPARABRISAS_AUTOMATICO",

  "LAVA_LUNETA": "LAVA_LUNETA",
  "LIMPIAPARABRISAS_TRASERO": "LAVA_LUNETA",
  "LIMPIA_PARABRISAS(ubicacion=PARTE_TRASERA)": "LAVA_LUNETA",

  "SAPITO": "SAPITO",
  "BOMBA_LIMPIAPARABRISAS": "SAPITO",

  // "LUZ_BAUL": "LUZ_BAUL",

  "SISTEMA_ABS": "SISTEMA_ABS",
  "MODULO_ABS": "SISTEMA_ABS",
  "BOMBA_ABS": "SISTEMA_ABS",

  "TABLERO": "TABLERO",

  // "TACOMETRO": "TACOMETRO",

  "TOMA_12V": "ENCENDEDOR",

  "TOMA_12V(ubicacion=PARTE_TRASERA)": "TOMA_12V_AUX",

  "VENTILADOR_RADIADOR": "VENTILADOR_RADIADOR",

  "VENTILADOR_DE_CLIMATIZADOR": "VENTILADOR_DE_CLIMATIZADOR",

  "LUZ_DIRECCIONAL": "LUZ_DIRECCIONAL",

  "LUCES_CIRCULACION_DIURNA": "LUCES_CIRCULACION_DIURNA",

  "LUZ_FRENO": "LUZ_FRENO",

  "LUZ_POSICION": "LUZ_POSICION",

  "LUZ_BAJA": "LUZ_BAJA",
  "LUZ_BAJA(ubicacion=LADO_IZQUIERDO)": "LUZ_BAJA_IZQ",
  "LUZ_BAJA(ubicacion=LADO_DERECHO)": "LUZ_BAJA_DER",

  "LUZ_RETROCESO": "LUZ_RETROCESO",

  "LUZ_ANTINIEBLA": "LUZ_ANTINIEBLA",

  "LUZ_DE_CAJA": "LUZ_DE_HABITACULO",
  "LUZ_DE_CORTESIA": "LUZ_DE_HABITACULO",
  "LUZ_CORTESIA": "LUZ_DE_HABITACULO",
  "LUZ_DE_HABITACULO": "LUZ_DE_HABITACULO",

  "LUZ_ALTA": "LUZ_ALTA",
  "LUZ_ALTA(ubicacion=LADO_IZQUIERDO)": "LUZ_ALTA_IZQ",
  "LUZ_ALTA(ubicacion=LADO_DERECHO)": "LUZ_ALTA_DER",
  "LUCES_DELANTERAS(ubicacion=LADO_IZQUIERDO)": "LUZ_ALTA_IZQ",
  "LUCES_DELANTERAS(ubicacion=LADO_DERECHO)": "LUZ_ALTA_DER",
  "LUCES_DELANTERAS": "LUZ_ALTA",

  "LUZ_BALIZA": "LUZ_BALIZA",

  "PANTALLA": "PANTALLA",
  "NAVEGADOR": "PANTALLA",

  "PRENDIDO_AUTOMATICO_LUCES": "PRENDIDO_AUTOMATICO_LUCES",

  "RELOJ": "RELOJ",

  "REPUESTO": "REPUESTO",
  "FUSIBLE_DE_REPUESTO": "REPUESTO",

  "LUNETA_TRASERA": "DESEMPANADOR_TRASERO", // Por "luneta trasera termica"

  "VELOCIDAD_VENTILADOR": "VENTILADOR_RADIADOR",
  "VELOCIDAD_VENTILADOR(of=VENTILADOR_RADIADOR,val=MAGNITUD_BAJA)": "VENTILADOR_RADIADOR",
  "VELOCIDAD_VENTILADOR(of=VENTILADOR_RADIADOR,val=MAGNITUD_ALTA)": "VENTILADOR_RADIADOR"
}

// Dimensions from www.littelfuse.com
// width and height in mm
// pngW and pngH are ratios of how larger is the png image due to white space and 3D projection
export const FUSE_TYPE_FORMATS = {
  'fuse': [
    '',
    'mini',
    'ato',
    'maxi',
    'cartridge-m',
    'cartridge-j',
    'cartridge-j-pal-283',
    'cartridge-j-pal-295',
    'cartridge-flf',
    'cartridge-flm',
    'micro', // En realidad es el micro2
    'micro3',
    'midi-ami',
    'high-current-mega',
    'torpedo-gbc-6ac',
    'glass-cartridge',
    'glass-cartridge-other'
  ],
  'relay': ['', 'ultra', 'full', 'half'],
  'fusepuller': ['', 'up', 'down'],
  'diode': ['', 'mini', 'ato'],
  'circuitbreaker': ['', 'mini', 'ato', 'maxi'],
  'connector': ['', 'generic', 'pins-10-lg', 'pins-18-lg', 'pins-10-md', 'pins-3-sm', 'obd1', 'obd2'],
  'fuse-shunt': ['', 'mini', 'ato'],
  'fuse-strip-hsb': null,
  'fuse-block': ['', 'honda-single', 'honda-double', 'honda-8', 'honda-generic'],
  'fuse-mpf': ['', '40-30-30', '40-40-40', '50-30-30', '50-30-40', '60-30-30'],
  'fuse-mpf2': ['', '30-20-40','40-30-30', '40-40-40', '50-30-30', '50-30-40', '50-30-50', '50-50-50'],
  'battery-terminal': ['', 'large', 'rounded'],
  'flasher': ['',],
  'other': null,
  'CALIBRATION': ['', 'normal', 'fit']
};

/**
 *
 * @type Record<string, Record<string, {
 *   height?: number; // Real life height in mm
 *   width?: number; // Real life width in mm
 *   pngW?: number; //
 *   pngH?: number;
 *   offsetY?: number;
 *   offsetX?: number;
 *   asymetrical?: boolean;
 *   symetricalYAxis?: boolean; // Needed to know how to rotate the fuse when flipping the diagram horizontally
 *   dontRotateVertical?: boolean; // Indicates that the same image should be used no matter the aspect ratio of the image
 * }>>
 */
export const FUSE_SIZES = {
  "fuse": {
    "mini": { width: 10.9, height: 3.8, pngW: 1.35, pngH: 2, offsetY: -0.05, offsetX: -0.02, visibleBox: {left: 0.08, width: 0.86, top: 0.12, height: 0.75} },
    "micro": { width: 9.1, height: 3.8, pngW: 1.35, pngH: 2, offsetY: -0.05, offsetX: -0.02, visibleBox: {left: 0.08, width: 0.86, top: 0.12, height: 0.75}  },
    "micro3": { width: 14.4, height: 4, pngW: 1.18, pngH: 1.85, offsetY: 0, offsetX: 0,  visibleBox: {left: 0.05, width: 0.9, top: 0.07, height: 0.8} },
    "ato": { width: 19.05, height: 5.08, pngW: 1.24, pngH: 1.96, offsetY: -0.05, visibleBox: {left: 0.05, width: 0.9, top: 0.1, height: 0.84} },
    "maxi": { width: 29.21, height: 8.85, pngW: 1.45, pngH: 3, offsetY: -0.02, visibleBox: {left: 0.13, top: 0.13, width: 0.74, height: 0.58} },

    "cartridge-j": { height: 12, width: 14.1, pngW: 1.063, pngH: 1.192, offsetY: -0.066, offsetX: -0.0033 },
    "cartridge-m": { height: 6.2, width: 10.7, pngW: 1.336, pngH: 1.29, offsetY: -0.1, visibleBox: {left: 0.11, width: 0.78, top: 0, height: 1}  },
    "cartridge-j-pal-283": { height: 16, width: 22.4, pngW: 2.13, pngH: 1.35, offsetY: -0.05  },
    "cartridge-j-pal-295": { height: 12.4, width: 63, pngW: 1.05, pngH: 1.87, offsetY: -0.13  },
    "cartridge-flm": { width: 22.4, height: 16, pngW: 1.075, pngH: 1.25, offsetY: -0.1, offsetX: 0 },
    "cartridge-flf": { width: 22.4, height: 15, pngW: 1.063, pngH: 1.192, offsetY: -0.066, offsetX: -0.0033 },

    "midi-ami": { height: 12.19, width: 41, pngW: 1.065, pngH: 1.33 },
    "high-current-mega": { height: 19, width: 68, pngW: 1.065, pngH: 1.33 },

    "torpedo-gbc-6ac": { height: 6, width: 25, pngW: 1.104, pngH: 1.5, offsetY: 0.044 },

    "glass-cartridge": { height: 103, width: 381, pngW: 1.073, pngH: 1.46 },
    "glass-cartridge-other": { pngW: 1.073, pngH: 1.46 },
  },
  "fuse-block": {
    "honda-double": { height: 17, width: 50, pngW: 1.15, pngH: 1.25, offsetY: -0, asymetrical: true, symetricalYAxis: true, iconInside: true },
    "honda-single": { height: 17, width: 25, pngW: 1.15, pngH: 1.15, offsetY: -0, asymetrical: true, symetricalYAxis: true, iconInside: true },
    "honda-8": {height: 7, width: 100, pngH: 2.85, pngW: 1.05, offsetX: -0, offsetY: -0.18, asymetrical: true,symetricalYAxis: true, iconInside: true },
    "honda-generic": {pngH: 2.85, pngW: 1.05, offsetX: -0, offsetY: -0.18, iconInside: true },
  },
  "fusepuller": {
    "up": {height: 9, width: 9, pngW: 1.31, pngH: 2.26, offsetY: -0.07, asymetrical: true},
    "down": {height: 9, width: 23,  pngW: 1.25, pngH: 1.6, offsetY: -0.04, asymetrical: true},
    "down-vertical": {height: 23, width: 9,  pngH: 1.25, pngW: 1.7, offsetY: -0.0, isVertical: true, asymetrical: true},
  },
  "relay": {
    "ultra": {height: 15, width: 16, pngW: 1.12, pngH: 1.292, offsetY: -0.0377, offsetX: -0.022, dontRotateVertical: true, iconInside: true, iconOverlay: true, iconOffsetX: 0.015, iconOffsetY: -0.1, visibleBox: {left: 0.07, width: 0.9, top: 0.05, height: 0.77}},
    "half": {height: 14.5, width: 23,  pngW: 1.13, pngH: 1.63, offsetY: -0.0699, offsetX: -0.0131, dontRotateVertical: true, iconInside: true, iconOverlay: true, iconOffsetX: 0.015, iconOffsetY: -0.15, visibleBox: {left: 0.07, width: 0.9, top: 0.03, height: 0.77}}, // AKA "Plug-in Micro ISO"
    "half-vertical": {width: 14.5, height: 23,  pngW: 1.20, pngH: 1.37, offsetY: -0.0537, offsetX: -0.03, isVertical: true, iconInside: true, iconOverlay: true, iconOffsetX: 0.04, iconOffsetY: -0.13, visibleBox: {left: 0.11, width: 0.83, top: 0.03, height: 0.85}},
    "full": {width: 27.8, height: 27.8, pngW: 1.12, pngH: 1.292, offsetY: -0.0377, offsetX: -0.022, dontRotateVertical: true, iconInside: true, iconOverlay: true, iconOffsetX: 0.015, iconOffsetY: -0.1, visibleBox: {left: 0.07, width: 0.9, top: 0.05, height: 0.77}}, // AKA "Plug-in Mini ISO"
    "all": {pngW: 1.12, pngH: 1.292, offsetY: -0.0377, offsetX: -0.0198, dontRotateVertical: true, iconInside: true, iconOffsetX: 0.021, iconOffsetY: -0.13, visibleBox: {left: 0.07, width: 0.9, top: 0.05, height: 0.77}}, // AKA "Plug-in Mini ISO"
  },
  "fuse-shunt": {
    "mini": { width: 10.9, height: 3.8, pngW: 1.35, pngH: 2, offsetY: -0.05, offsetX: -0.02 },
    "ato": { width: 19.05, height: 5.08, pngW: 1.35, pngH: 2, offsetY: -0.05, offsetX: -0.02 }
  },
  "fuse-mpf": {
    "all": {height: 15.6, width: 32.6, pngW: 1, pngH: 1.3, offsetY: -0.11, asymetrical: true, iconInside: true },
  },
  "fuse-mpf2": {
    "all":  {height: 16, width: 28.5, pngW: 1, pngH: 1.125, offsetY: -0.05, iconInside: true },
  },
  "diode": {
    "all": { pngW: 1.22, pngH: 2.4, offsetY: -0.1 },
    "mini": { width: 10.9, height: 3.8, pngW: 1.22, pngH: 2.5, offsetY: -0.1, offsetX: 0 },
    // "ato": { width: 19.05, height: 5.08, pngW: 1.22, pngH: 2.5, offsetY: -0.1 }
  },
  'circuitbreaker': {
    "*": {pngW: 1.2, pngH: 1.8,  offsetY: -0.13, visibleBox: {left: 0.08, width: 0.84, top: 0.14, height: 0.75} },
    "mini": { width: 12.4, height: 5.7},
    "ato": { width: 19.05, height: 5.7 },
    "maxi": { width: 29.21, height: 8.85 },
  },
  'fuse-strip-hsb': {
    'all': { width: 42, height: 12, pngW: 1.17, pngH: 1.60, iconInside: true }
  },
  'battery-terminal': {
    'all': { width: 16, height: 16, pngW: 1.88, pngH: 1.245, offsetY: 0.075, offsetX: 0.235, asymetrical: true},
    'rounded': { width: 16, height: 16, pngW: 2.27, pngH: 1, offsetY: 0, offsetX: 0.28, asymetrical: true},
    'large': { width: 16, height: 16, pngW: 3.25, pngH: 1.29, offsetY: 0, offsetX: 0.27, asymetrical: true}
  },
  'connector' : {
    'all': {pngW: 1.11, pngH: 1.44*0.9, offsetY: -0.05, asymetrical: true, symetricalYAxis: true, visibleBox: {left: 0.07, width: 0.86, top: 0.08, height: 0.7}},
    'pins-10-lg': {pngW: 1.11, pngH: 1.44*0.8, offsetY: -0.03, asymetrical: true, symetricalYAxis: true, visibleBox: {left: 0.07, width: 0.86, top: 0.08, height: 0.7}},
    'pins-18-lg': {pngW: 1.11, pngH: 1.44*0.9, offsetY: -0.05, asymetrical: true, symetricalYAxis: true, visibleBox: {left: 0.07, width: 0.86, top: 0.08, height: 0.7}},
    'pins-10-md': {pngW: 1.17, pngH: 1.31, offsetY: -0.05, asymetrical: true, symetricalYAxis: true, visibleBox: {left: 0.09, width: 0.82, top: 0.08, height: 0.8}},
    'pins-3-sm': {pngW: 1.16, pngH: 1.31, offsetY: -0.04, asymetrical: true, symetricalYAxis: true, visibleBox: {left: 0.09, width: 0.82, top: 0.08, height: 0.8}},
    'obd2': {pngW: 1.1, pngH: 1.2},
    'obd1': {pngW: 1.1, pngH: 1.2}
  },
  'flasher': {
    'all': { /*width: 36.0, height: 36.0,*/ pngW: 1.11, pngH: 1.16,  offsetY: -0.04, dontRotateVertical: true },
  },
  'other': {
    'all': {pngW: 1, pngH: 1, dontRotateVertical: true, iconInside: true, visibleBox: {left: 0.5, width: 0.1, top: 0.4, height: 0.1}},
  },
  'CALIBRATION': { // A pixel perfect measured diagram used to calibrate the layout algorithm
    'normal': { width: 100, height: 25, pngW: 1.25, pngH: 2.8, offsetY: -0.1785, offsetX: 0.06 },
    'fit': { width: 100, height: 25, pngW: 1, pngH: 1, offsetY: 0, offsetX: 0 }
  },
}

for(const format of Object.keys(FUSE_SIZES)) {
  let subformats = FUSE_SIZES[format];
  if(subformats) {
    let applyToAll = subformats["*"];
    if(applyToAll) {
      delete subformats["*"];
      for(const subformat of Object.keys(subformats)) {
        subformats[subformat] = {...applyToAll, ...subformats[subformat]};
      }
    }
  }
}

let FUSE_FORMATS_NAMES = {
  'fuse': {
    'mini': 'MINI',
    'ato': 'ATO',
    'maxi': 'MAXI',
    'cartridge-m': 'FMM/MCase',
    'cartridge-j': 'FMX/JCase',
    'cartridge-j-pal-283': 'FLD/PAL 283',
    'cartridge-j-pal-295': 'FLB/PAL 295',
    'cartridge-flf': 'FLF/PAL 293',
    'cartridge-flm': 'FLM',
    'micro': 'MICRO2',
    'micro3': 'MICRO3',
    'midi-ami': 'MIDI',
    'high-current-mega': 'MEGA',
    'torpedo-gbc-6ac': 'Bosch/GBD/6AC',
    'glass-cartridge': 'Glass cartridge 5AG',
    'glass-cartridge-any-size': 'Glass cartridge',
  },
  'relay': {},
  'fusepuller': {},
  'diode': {
    'mini': 'MINI',
    'ato': 'ATO'
  },
  'circuitbreaker': {
    'mini': 'MINI',
    'ato': 'ATO',
    'maxi': 'MAXI',
  },
  'connector': {
    // 'generic': '',
    'obd1': 'OBD1',
    'obd2': 'OBD2'
  },
  'fuse-shunt': {
    'mini': 'MINI Shunt',
    'ato': 'ATO Shunt',
  },
  'fuse-strip-hsb': {},
  'fuse-block': {
    'honda-single': 'MUSB',
    'honda-double': 'MUSB',
    'honda-8': 'MUSB',
    'honda-generic': 'MUSB',
  },
  'fuse-mpf': {},
  'fuse-mpf2': {},
  'battery-terminal': {},
  'fuse-other': {},
  'flasher': {},
  'other': {},
};

export const FUSE_NAMES_ES = {
  types: {
    'fuse': 'Fusible',
    'relay': 'Relé',
    'fusepuller': 'Extractor de fusible',
    'diode': 'Diodo',
    'circuitbreaker': 'Circuit breaker',
    'connector': 'Conector',
    'fuse-shunt': 'Fusible',
    'fuse-strip-hsb': 'Fusible',
    'fuse-block': 'Bloque de multifusible',
    'fuse-mpf': 'Fusible MPF',
    'fuse-mpf2': 'Fusible MPF2',
    'battery-terminal': 'Terminal de la batería',
    'fuse-other': 'Fusible',
    'flasher': 'Destellador',
    'other': 'Otro'
  },
  formats: FUSE_FORMATS_NAMES,
  descriptions: {
    'connector': {
      'obd1': 'Conector para el escaner de diagnóstico (OBD1)',
      'obd2': 'Conector para el escaner de diagnóstico (OBD2)'
    },
    'fusepuller': {
      _default: 'Herramienta para la extracción de los fusibles.'
    },
  },
  messages: {
    'touch-to-see-function': 'Toca un fusible para ver su función'
  }
};



export const FUSE_NAMES_EN = {
  types: {
    'fuse': 'Fuse',
    'relay': 'Relay',
    'fusepuller': 'Fuse puller',
    'diode': 'Diode',
    'circuitbreaker': 'Circuit breaker',
    'connector': 'Connector',
    'fuse-shunt': 'Fuse',
    'fuse-strip-hsb': 'Fuse',
    'fuse-block': 'Multi fuse block',
    'fuse-mpf': 'Fuse MPF',
    'fuse-mpf2': 'Fuse MPF2',
    'battery-terminal': 'Battery terminal',
    'fuse-other': 'Fuse',
    'flasher': 'Flasher',
    'other': 'Other'
  },
  formats: FUSE_FORMATS_NAMES,
  descriptions: {
    'connector': {
      'obd1': 'On-board diagnostic port (OBD1)',
      'obd2': 'On-board diagnostic port (OBD2)'
    },
    'fusepuller': {
      _default: 'Extraction tool for fuse replacement.'
    },
  },
  messages: {
    'touch-to-see-function': 'Touch a fuse to see its function'
  }
};

export const FUSE_NAMES_PT = {
  "types": {
    "fuse": "Fusível",
    "relay": "Relé",
    "fusepuller": "Extrator de fusível",
    "diode": "Diodo",
    "circuitbreaker": "Disjuntor",
    "connector": "Conector",
    "fuse-shunt": "Fusível",
    "fuse-strip-hsb": "Fusível",
    "fuse-block": "Bloco de fusíveis",
    "fuse-mpf": "Fusível MPF",
    "fuse-mpf2": "Fusível MPF2",
    "battery-terminal": "Terminal de bateria",
    "fuse-other": "Fusível",
    "flasher": "Intermitente",
    "other": "Outro"
  },
  "formats": FUSE_FORMATS_NAMES,
  "descriptions": {
    "connector": {
      "obd1": "Conector para o scanner de diagnóstico (OBD1)",
      "obd2": "Conector para o scanner de diagnóstico (OBD2)"
    },
    "fusepuller": {
      "_default": "Ferramenta para remover fusíveis."
    },
  },
  messages: {
    'touch-to-see-function': 'Toque um fusível para ver sua função'
  }
}

export const FUSE_NAMES_LOCALIZED = {
  es: FUSE_NAMES_ES,
  en: FUSE_NAMES_EN,
  pt: FUSE_NAMES_PT
}

export const FUSE_COLORS = {
  mini: {
    "1": "#373737",
    "2": "#7C9086",
    "3": "#724998",
    "4": "#C98F93",
    "5": "#D88C4E",
    "7.5": "#622f08",
    "10": "#b70000",
    "15": "#0124bf",
    "20": "#C7AF32",
    "25": "#BCA58F",
    "30": "#1b925d",
    "35": "#64ad97",
    "40": "#DA560B",
    "50": "#900000"
  },
  'cartridge-j': {
    "15": "#ADAEA8",
    "20": "#0B80EC",
    "25": "#EBE8CC",
    "30": "#F8B7C7",
    "40": "#0EB159",
    "45": "#D21621",
    "50": "#C5240E",
    "60": "#F5D150",
    "70": "#5A3F1F",
    "80": "#1D1A18",
    "100": "#1A3C7F",
    "120": "#D0C5B9",
    "140": "#5B123E",
  },
  'torpedo-gbc-6ac': {
    "5": "#DFCB17",
    "8": "#FBFBFB",
    "10": "#DF8117",
    "16": "#CC383D",
    "20": "#CECFA9",
    "25": "#407BB6",
    "30": "#359860"
  }
};

FUSE_COLORS.ato = FUSE_COLORS.mini
FUSE_COLORS.micro = FUSE_COLORS.mini
FUSE_COLORS.micro3 = FUSE_COLORS.mini;
FUSE_COLORS["cartridge-m"] = FUSE_COLORS['cartridge-j'];
FUSE_COLORS["cartridge-j-pal-283"] = FUSE_COLORS['cartridge-j'];
FUSE_COLORS["cartridge-j-pal-295"] = FUSE_COLORS['cartridge-j'];
FUSE_COLORS["cartridge-flm"] = FUSE_COLORS['cartridge-j'];
FUSE_COLORS["cartridge-flf"] = FUSE_COLORS['cartridge-j'];

export const semanticToIconMap = iconsForSemantic;

export function getSemanticIcon(semantic) {
  let icon = iconsForSemantic[semantic];

  // Try renames
  if(!icon) {
    semantic = semantic.replace(/^RELAY\(protects=(.*)\)$/, '$1');
    semantic = semantic.replace(/^FUSIBLE\(protects=(.*)\)$/, '$1');
    semantic = semantic.replace(/^MOTOR_IMPULSOR\(of=(.*)\)$/, '$1');
    semantic = semantic.replace(/^LAMPARA\(of=(.*)\)$/, '$1');
    semantic = semantic.replace(/^COMANDO\(of=(.*)\)$/, '$1');

    // Try with those
    icon = iconsForSemantic[semantic];

    // As last resort, try without the modifiers
    if(!icon) {
      semantic = semantic.replace(/^([\w_]+)\((ubicacion|velocidadVentilador)=(.*)\)$/, '$1');
    }
    icon = iconsForSemantic[semantic];
  }

  return icon;
}
