import _ from 'lodash';

export function SpecsJsonTable({ specJsons }) {
  const renderValue = (value) => {
    if (_.isBoolean(value)) {
      return <span className="text-success">{value ? '✅' : '⛔'}</span>;
    } else if (_.isObject(value) && value.value !== undefined && value.unit !== undefined) {
      return <span>
        <span className='text-info'>{value.value}</span> <span className="text-info">{value.unit}</span>
      </span>;
    } else if (_.isString(value)) {
      return <span className='text-danger'>"{value}"</span>;
    } else if (_.isNull(value)) {
    } else if (_.isNumber(value)) {
      return <span className='text-primary'>{value}</span>;
    } else if (_.isNull(value)) {
      return <span className="text-muted">null</span>;
    } else if (_.isEmpty(value)) {
      return '-';
    } else {
      return JSON.stringify(value);
    }
  };

  const colSpan = specJsons.length + 1;

  const renderRows = (path, value, level) => {
    let margin = { paddingLeft: level * 30 };
    
    return Object.entries(value).map(([key, value]) => {
      let fullPath = [... path, key].join('.');
      if (_.isPlainObject(value) && value.value === undefined) {
        return <><tr key={fullPath} className='bg-light'>
          <td className="mr-2" colSpan={colSpan} style={margin}>{key}:</td>
        </tr>
          {renderRows([... path, key], value, level + 1)}
        </>;
      } else if (_.isArray(value)) {
        return <><tr key={fullPath} className='bg-light'>
          <td className="mr-2" colSpan={colSpan} style={margin}>{key}:</td>
        </tr>
          {value.map((v, i) => renderRows([... path, key], {[i]: v}, level + 1))}
        </>;
      } else {
        const values = specJsons.map(json => _.get(json, fullPath));
        if(_.uniq(values.map(JSON.stringify)).length === 1) {
          return <tr key={fullPath} className=''>
          <td className="mr-2" style={margin}>{key}:</td>
          <td colSpan={colSpan} className='text-center'>{renderValue(_.get(specJsons[0], fullPath))}</td>   
        </tr>;
        } else {
          return <tr key={fullPath} className='bg-light-warning'>
            <td className="mr-2" style={margin}>{key}:</td>
            {specJsons.map((json, i) => <td key={i} className='text-center'>{renderValue(_.get(json, fullPath))}</td>)}      
          </tr>;
        }
      }
    });
  };

  let specs = _.merge({}, ... specJsons);

  return <table className="specs-json-table-comparison table table-sm monospace" style={{ fontSize: '12px', width: 'inherit' }}>
    <thead>
      <tr>
        <th>Specs</th>
        {specJsons.map((specJson, i) => <th key={i} style={{ width: '200px' }} className='text-center'>Specs {i+1}</th>)}
      </tr>
    </thead>
    <tbody>
      {renderRows([], specs, 0)}
    </tbody>
  </table>;
}
