import { useState } from 'react';

export default function PromptModal({ message = '', initialText, onSave }) {
  const [text, setText] = useState(initialText);
  return <div className={'p-2'}>
    {message}
    <textarea className={'form-control form-control-small break-word-all'} rows={10} value={text} onChange={e => setText(e.target.value)} />

    <button className={'btn btn-primary mt-2'} onClick={() => onSave(text)}>
      OK
    </button>
  </div>
}
