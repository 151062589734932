import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';

/**
 * TextSearchInput - A simple text input with debounced onChange
 * @param {Object} props - The component props
 * @param {string} props.value - The value of the input
 * @param {function} props.onChange - The function to call when the input value changes
 * @param {string} [props.className] - Optional CSS class name
 * @param {Object} [props.refObj] - Optional ref object for the input
 * @param {boolean} [props.debounce] - Whether to debounce the onChange event
 * @param {string} [props.placeholder] - Placeholder text for the input
 * @param {function} [props.onEnter] - The function to call when the Enter key is pressed
 * @param {Object} [props.otherProps] - Additional props to pass to the input element
 */
export default function TextSearchInput({
                                          value = '',
                                          onChange,
                                          className,
                                          refObj,
                                          debounce = false,
                                          placeholder,
                                          onEnter,
                                          ...otherProps
                                        }) {

  const [localValue, setLocalValue] = useState(value);

  // Update internal state when external value prop changes
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  // Create memoized debounced handler that won't recreate on every render
  const debouncedOnChange = useCallback(_.debounce((newValue) => onChange(newValue), 250), [onChange]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);

    if (debounce) {
      debouncedOnChange(newValue);
    } else {
      onChange(newValue);
    }
  };

  return (
    <input
      ref={refObj}
      className={`form-control ${className || ''}`}
      value={localValue}
      placeholder={placeholder || 'Search...'}
      type="text"
      onChange={handleChange}
      onKeyDown={e => e.key === 'Enter' && onEnter && onEnter()}
      {...otherProps}
    />
  );
}
