import React from 'react'

/**
 * BadgeId - A component that displays an id in a badge format
 * @param {Object} props - The component props
 * @param {string} props.id - The id to display
 * @param {boolean} [props.large] - Whether to display the id in a large format
 * @param {string} [props.className] - Optional CSS class name
 * @param {boolean} [props.dark] - Whether to display the id in a dark color
 * @param {Object} [props.other] - Additional props to pass to the span element
 * @returns {React.ReactElement} The rendered component
 */
export default function BadgeId({id, large, className, dark, ... other}) {
  return <span className={`no-select ${large ? '' : 'small'} ${className || ''}`}>id&nbsp;<code className={`monospace ${dark ? 'text-light' : 'text-secondary'} select-text`} {... other}>{id || '-'}</code>  </span>
}
