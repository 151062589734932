import { useContext, useState } from 'react';
import _ from 'lodash';

import LegoAdminPageContext from '../../legoAdminPageContext';
import useAsyncEffect from '../../../components/common/react-hooks/useAsyncEffect';
import { FactoidSpecs } from './FactoidSpecs';

export default function FactoidViewer({ids}) {
  const { page } = useContext(LegoAdminPageContext);
  const [objs, setObjects] = useState(null);

  useAsyncEffect(async () => {
    const data = await page.service('services/cerokm/sourcefactoid').find({query: {_id: {$in: ids}}})
    const fragments = await page.service('services/cerokm/sourcefragment').find({query: {_id: {$in: _.map(data, 'sourceFragmentId')}}})
    const fragmentsById = _.keyBy(fragments, '_id');
    data.forEach(d => d.fragment = fragmentsById[d.sourceFragmentId]);

    setObjects(data);
  }, [ids]);

  if(!objs) {
    return <span className={'text-secondary'}>Loading...</span>
  }

  return <div className={'p-2 small'}>
    {objs.map(({_id, sourceFragmentId, derivedSpecs, fragment}) => <div className={'row border-bottom'} key={_id}>
      <div className={'col-6'}>
        {fragment.text}
      </div>
      <div className={'col-6'}>
        <FactoidSpecs factoid={{derivedSpecs}}/>
      </div>
    </div>)}
  </div>
}
