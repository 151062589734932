import { useContext, useState } from 'react';
import _ from 'lodash';

import LegoAdminPageContext from '../../legoAdminPageContext';
import useAsyncEffect from '../../../components/common/react-hooks/useAsyncEffect';
import { FactoidSpecs } from './FactoidSpecs';
import SpecsSchemaChangePreview from './SpecsSchemaChangePreview';
import { IconButton } from '../../../components/common/IconButton';
import { Icon } from '../../../components/common/Icon';
import BadgeId from '../../../components/common/BadgeId';
import AIModelBadge from '../../../components/ai/AIModelBadge';

export default function SchemaFixesExplorer({ filters = null, onApplyFix, onRejectFix, forceRefresh, temporalFixesState = {} }) {
  const { page } = useContext(LegoAdminPageContext);
  
  const [objs, setObjects] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFixesById, setSelectedFixes] = useState({});

  // Find all fixes that are not rejected
  useAsyncEffect(async () => {
    setIsLoading(true);
    const data = await page.service('services/cerokm/schemafix').find({ query: { state: 'pending' } })
    const allFragmentIds = _.map(data, 'sourceFragmentIds');
    const fragments = await page.service('services/cerokm/sourcefragment').find({ query: { _id: { $in: _.flatten(allFragmentIds) } } })
    const fragmentsById = _.keyBy(fragments, '_id');
    data.forEach(d => d.fragments = _.map(d.sourceFragmentIds, id => fragmentsById[id]));

    setObjects(data);
    setSelectedFixes({})
    setIsLoading(false);
  }, [filters, forceRefresh]);

  function apply(ops, fix) {
    let selection = selectedFixesById[fix._id];
    if(selection) {
      ops = _.map(selection, i => ops[i]);
    }
    onApplyFix(ops,fix);
  }

  function reject(fix) {
    onRejectFix(fix);
  }

  if (!objs) {
    return <span className={'text-secondary'}>Loading...</span>;
  }

  const onSelectionChange = (fixId, selection) => {
    selectedFixesById[fixId] = selection;
    console.log('selectedFixesById', selectedFixesById);
    setSelectedFixes({... selectedFixesById});
  }


  return <div className={isLoading ? 'translucent' : ''}>
    {objs.map((fix) => {
      const { _id, fragments, fixOperations, fixUsage, derivedBy, promptVersion, schemaVersion } = fix;

      const fixState = temporalFixesState[_id];
      const fragmentIds = fragments.map(f => f._id);

      const btnAccept = fixState !== 'accept' && <span className={'btn btn-sm btn-success mr-1'} onClick={() => apply(fixOperations, fix)}>
        <Icon icon={'check_circle'} level={'white'} /> Apply {selectedFixesById[_id]?.length ?? fixOperations.length} changes
      </span>;

      const btnReject = !fixState && <span className={'btn btn-sm btn-danger'} onClick={() => reject(fix)}>
        <Icon icon={'close'} level={'white'} /> Reject
      </span>;


      const isAllSelected = !selectedFixesById[_id] || selectedFixesById[_id]?.length === fixOperations.length;
      const noneSelected = selectedFixesById[_id]?.length === 0;
      const selectIcon = isAllSelected ? 'check_box' : noneSelected ? 'check_box_outline_blank' : 'indeterminate_check_box';
      const checkboxAll = <IconButton icon={selectIcon} level={'white'} className={'mr-1 ml-2'} onClick={() => onSelectionChange(_id, isAllSelected ? [] : null)}/>;

      const stateClass = fixState === 'accept' ? 'bg-success' : fixState === 'reject' ? 'bg-danger' : 'bg-secondary';

      return <div className={`rounded mb-2 p-1 border ${stateClass}`} key={_id}>
        <h6 className={'text-white pb-1 m-0 pl-1 d-flex justify-content-between align-items-center'}>
          <span>
            Fixes based on {fragments.length} fragments  <BadgeId dark id={_id} />
          </span>

          <span>
            <AIModelBadge model={derivedBy} showName={true} />
            <span className={'badge badge-dark align-middle ml-2'}>Schema {schemaVersion}
            </span>
            <span className={'badge badge-violeta align-middle ml-2'}>Pr. {promptVersion}
            </span>
          </span>
        </h6>

        <div className={'text-left mb-1'}>
          { checkboxAll }
          { btnAccept }
          { btnReject }
        </div>

        <div className={'bg-light rounded text-normal p-1'}>
          <SpecsSchemaChangePreview selection={selectedFixesById[_id]} onSelectionChange={(sel) => onSelectionChange(_id, sel)} changes={fixOperations} usage={fixUsage} />
        </div>
      </div>;
    })}
  </div>
}
