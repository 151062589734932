import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LivePage from '../LivePage';
import LegoAdminPageContext from '../legoAdminPageContext';
import BadgeId from '../../components/common/BadgeId';
import { IconButton } from '../../components/common/IconButton';
import submenuCerokm from '../menus/submenu-cerokm';
import LiveTable from './components/LiveTable';
import { FactoidSpecs } from './components/FactoidSpecs';
import AIModelBadge from '../../components/ai/AIModelBadge';

dayjs.extend(relativeTime);

class CeroKmFactoidsManager extends LivePage {
  constructor(props) {
    super(props);
    this.svcSource = this.service('services/cerokm/source');
    this.svcFragments = this.service('services/cerokm/sourcefragment');
    this.svcFactoids = this.service('services/cerokm/sourcefactoid');

    this.tableRef = React.createRef();
    this.submenu = submenuCerokm;
  }

  delete(obj) {
    this.runAsync(this.svcFactoids.remove(obj._id).then(() => this.tableRef.current.refreshIds([obj._id])));
  }

  renderPageBody() {
    const {filters, highlightedGroups} = this.state;

    const stateBkgColor = {
      ignore: 'bg-secondary',
      missing: 'bg-warning',
      complete: 'bg-success'
    }

    const columns = {
      SourceFragment: o => <BadgeId id={o.sourceFragmentId}/>,
      Revision: o => o.manualRevision || '',
      State: o => {
        if(o.isObsolete) {
          return <td className={'text-white align-middle text-center bg-danger'}>
            Obsolete
            <span className={'badge badge-dark'}>
              {o.obsoleteReason}
            </span>
          </td>
        }

        const state = o.state;
        const stateClass = stateBkgColor[state] || '';
        return <td className={'text-white align-middle text-center ' + stateClass}>{state}</td>
      },
      DerivedSpecs: o => <FactoidSpecs factoid={o}/>,
      "Derived by": o => <span className={'small'}><AIModelBadge model={o.derivedBy}/> {o.derivedBy}</span>,
      "Prompt version": o => o.promptVersion,
      "Schema version": o => o.schemaVersion,
      Actions: o => <>
        {/*<IconButton onClick={() => this.filterByGroup(o)} level={hasGroupFilter === o._id ? 'success' : 'secondary'} icon={'filter_alt'} description={'Filter fragments'}/>*/}
        {/*<IconButton onClick={() => openEdit(o)} level={'primary'} icon={'edit'} description={'Edit'}/>*/}
        <IconButton onClick={() => this.delete(o)} level={'danger'} icon={'delete'} description={'Delete'}/>
      </>
    };

    return <div className={'px-3'}>
      <h5 className={'mt-3'}>
        Factoids
          <IconButton icon={'sync'} level={'primary'} className={'ml-2'} onClick={() => this.tableRef.current.refresh()}/>
      </h5>

      <LiveTable service={this.svcFactoids} columns={columns} ref={this.tableRef}/>
    </div>;
  }
}

CeroKmFactoidsManager.contextType = LegoAdminPageContext;


// For development
function FuncComponent(props) {
  return <CeroKmFactoidsManager {...props}/>;
}

export default FuncComponent;
