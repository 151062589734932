export default [
  { to: '/legos', display: 'Legos', contributorsAllowed: true },
  { to: '/tasks', display: 'Tasks', contributorsAllowed: true },
  { to: '/manuals', display: 'Manuals', contributorsAllowed: true },
  { to: '/models', display: 'Models' },
  { to: '/dataviews', display: 'Dataviews' },
  { to: '/stats', display: 'Stats' },
  { to: '/emails', display: 'Emails' },
  { to: '/tagging', display: 'Tags' },
  { to: '/facets', display: 'Facets' },
  { to: '/trims', display: 'Trims' },
  // { to: '/users', display: 'Users' },
  { to: '/crawl', display: 'Crawling' },
  { to: '/scripts', display: 'Scripts' },
  { to: '/cerokm', display: '0km' },
]
