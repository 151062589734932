import gemini from '../../public/images/logo-gemini.svg?url';
import opeanai from '../../public/images/logo-gpt.svg?url';
import anthropic from '../../public/images/logo-claude.svg?url';

const images = {
  'gemini': gemini,
  'anthropic': anthropic,
  'openai': opeanai,
}

export default function AIModelBadge({model, showName = false}) {
  let modelGroup = '';
  if(model.match(/^gpt-/)) {
    modelGroup = 'openai';
  } else if(model.match(/^claude-/)) {
    modelGroup = 'anthropic';
  } else if(model.match(/^gemini-/)) {
    modelGroup = 'gemini';
  }

  return <span className={'badge badge-light'}>
    <img alt={model} style={{height: '18px', width: '18px', objectFit: 'contain'}} title={model} className={''} src={images[modelGroup]}/>
    {showName ? ' '+model : null}
  </span>;
}
