import { useContext, useEffect, useState } from 'react';
import LegoAdminPageContext from '../../legoAdminPageContext';
import _ from 'lodash';


function shortenCommonWords(text) {
  return text
  .replace('4-Wheel Drive', '4x4')
  .replace('Rear-Wheel Drive', 'RWD')
  .replace('All-Wheel Drive', 'AWD')
  .replace('Front-Wheel Drive', 'FWD')
  .replace('Double Cab', 'D.Cab')
  .replace('4-Cyl. Turbo Engine', '')
  .replace('Hybrid Powertrain', '⚡')
  .replace(/(\d)-ft. bed/, '$1\'bed')
  .replace('4-Cyl. Engine', '')
}

export default function SpecsGroupSelector({context, initialSelection, multi = false, forceRefresh = null, filter = null, onGroupSelected, highlightedIds = null}) {
  const { page } = useContext(LegoAdminPageContext);

  const [objects, setObjects] = useState(null);
  const [textFilter, setTextFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(_.compact([initialSelection]));

  const selectGroup = (group) => {
    if(selected.includes(group._id)) {
      const newIds = _.without(selected, group._id);
      setSelected(newIds);
      onGroupSelected(multi ? newIds : undefined);
    } else {
      if(multi) {
        const newIds = [...selected, group._id];
        setSelected(newIds);
        onGroupSelected(newIds);
      } else {
        onGroupSelected(group);
        setSelected([group._id]);
      }
    }
  }

  // When initialSelection changes, reset the selected groups
  useEffect(() => {
    setSelected(_.compact([initialSelection]));
  }, [initialSelection]);

  useEffect(() => {
    setLoading(true);

    if(context?.modelId) {
      filter = {... filter, 'context.modelId': context.modelId};
    }

    page.service('services/cerokm/specsgroup').find({query: {... filter}}).then(setObjects).finally(() => setLoading(false));
  }, [forceRefresh, context, filter]);


  if(objects) {
    let groupedByBasedOn = _.groupBy(objects, 'basedOn');

    let noGroup = _.sortBy(objects.filter(o => !o.basedOn), 'name');

    let modelIdYear = _.groupBy(objects, o => o.context.modelId + '-' + o.context.year);

    return <div className={'rounded bg-light border p-1 overflow-auto'}>
      {_.keys(modelIdYear).length === 1 ?
        <div className={'p-1 font-weight-bold'}>{objects[0].context.modelId} {objects[0].context.year}</div>
        : null}
      <div className={'d-flex align-items-start rounded'}>
      {
        noGroup.map(o => <div key={o._id} className={'p-05 m-05 bg-dark text-white border rounded px-1'}>
          <div className={'text-center d-flex align-items-center justify-content-center mb-1'} style={{minHeight: '50px'}}>{o.name}</div>

          {_.map(groupedByBasedOn[o._id], (subtrim) => {
            const isSelected = selected.includes(subtrim._id);

            return <div key={subtrim._id}>
              <span className={`monospace btn btn-xs btn-${isSelected ? 'primary' : 'secondary'} text-nowrap mb-1 ${highlightedIds?.includes(subtrim._id) ? 'highlight-warn' : ''}`}
                         onClick={() => selectGroup(subtrim)}>
              {shortenCommonWords(subtrim.name)}
            </span></div>;
          })}
      </div>)
      }
    </div>
    </div>
  } else if(loading) {
    return <div className={'p-3'}>Loading...</div>
  } else {
    return <div className={'p-3'}>No specs groups found</div>
  }
}
