import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import SingleTextEditor from '../common/editors/SingleTextEditor';

export function useMounted() {
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return function useMountedEffectCleanup() {
      mountedRef.current = false;
    };
  }, []);
  return useCallback(() => mountedRef.current, [mountedRef]);
}

export default function AskAI({prompt, model = null}) {
  const {page} = useContext(LegoAdminPageContext);

  const [text, setText] = useState('');
  const [lastPrompt, setLastPrompt] = useState(prompt);
  const [activePromptId, setActivePromptId] = useState(null);
  const activePromptIdRef = useRef(null);
  const isMounted = useMounted();

  const gtpServiceRef = useRef(page.service('services/gpt/prompt')); // Store service reference

  useEffect(() => {
    tryParseGPT(lastPrompt);
  }, []);

  useEffect(() => {
    let myId = activePromptIdRef.current;
    let partialOutput = '';
    // let active = true;

    const promptProgress = ({ promptId, newOutput }) => {
      if (myId === promptId) {
        if(isMounted()) {
          partialOutput = partialOutput + newOutput;
          setText(partialOutput);
        }
        // console.log(`[${myId}] Prompt progress`);
      } else {
        // console.log(`[${myId}] Skipping other promptId `, promptId);
      }
    };

    if(myId) {
      console.log(`[${myId}] ON Listening updates`)
      gtpServiceRef.current.on('prompt-progress', promptProgress);
    }

    return () => {
      if(myId) {
        console.log(`[${myId}] OFF Stopped listening updates`)
        // active = false;
        gtpServiceRef.current.removeListener('prompt-progress', promptProgress);
      }
    }
  }, [activePromptId]);

  const tryParseGPT = useCallback(async (prompt) => {
    try {
      console.log('Listening updates')
      let promptId = Math.ceil(Math.random()*1000) //new Date().valueOf();
      setActivePromptId(promptId);
      activePromptIdRef.current = promptId;
      let res = await gtpServiceRef.current.create({ prompt, promptId });

      if(promptId === activePromptIdRef.current && isMounted()) {
        console.log('Prompt id FINISHED', promptId);
        setText(res);
        activePromptIdRef.current = null;
        setActivePromptId(null);
      }
    } finally {
      // gtpServiceRef.current.removeListener('prompt-progress', promptProgress);
    }
  }, []); // Include text in dependencies array

  // Rest of your component...

  return (
    <div>
      <textarea rows={6} className={'form-control form-control-small'} value={lastPrompt} onChange={e => setLastPrompt(e.target.value)}/>
      <button className={'btn-primary btn mt-1'} onClick={() => tryParseGPT(lastPrompt)}>Ask AI</button>

      <pre style={{whiteSpace: 'pre-wrap', minHeight: '30vh'}} className={'text-breakall broder rounde p-2 bg-light mt-2'}>
        {text}
      </pre>
    </div>
  );
}
