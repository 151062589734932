import React from 'react';
import _ from 'lodash';
import LivePage from '../LivePage';
import LegoAdminPageContext from '../legoAdminPageContext';
import SchemaEditor, { getCleanSchema } from './spec-schema-editor/SpecSchemaEditor';
import submenuCerokm from '../menus/submenu-cerokm';
import BadgeId from '../../components/common/BadgeId';
import { UserAvatar } from '../../components/common/UserAvatar';
import TextDiff from '../../components/test-sets/TextDiff';
import { IconButton } from '../../components/common/IconButton';
import { SchemaChangeOpsList } from './components/SchemaChangeOpsList';
import SaveSchemaChangePreview from './components/SaveSchemaChangePreview';
import SchemaFixesExplorer from './components/SchemaFixesExplorer';
import { fromNow } from '../../components/common/TimeUtils';

class CeroKmSchemaManager extends LivePage {
  constructor(props) {
    super(props);
    // this.svcSource = this.service('services/cerokm/source');
    // this.svcFragments = this.service('services/cerokm/sourcefragment');
    this.svcSchemas = this.service('services/cerokm/schema');
    this.submenu = submenuCerokm;
    this.fullScreen = true;

    this.editorRef = React.createRef();

    this.svcSchemas.find({query: {$sort: {createdAt: -1}, $limit: 1}}).then(res => {
      this.setState({ originalSchema: res[0] || {schema: {type: 'object', properties: {}}} });
    });

    this.state.temporalFixesState = {};
    this.state.appliedFixes = []; 
  }

  saveChanges() {
    if(this.hasSchemaChanges()) {
      this.openModal(
        <SaveSchemaChangePreview 
          operations={this.state.operations} 
          changedSchema={this.state.changedSchema}
          originalSchema={this.state.originalSchema}
          fixesState={this.state.temporalFixesState}
        appliedFixes={this.state.appliedFixes}
        onSavedChanges={(savedNewDoc) => {
          this.setState({ originalSchema: savedNewDoc, operations: [], appliedFixes: [], temporalFixesState: {}, forceRefresh: new Date().valueOf() });
          this.closeModal();
        }}
      />, 
        {title: 'Save changes - Schema change impact'}
      );
    } else {
      this.runAsync(async () => {
        let toReject = _.filter(_.toPairs(this.state.temporalFixesState), ([id, state]) => state === 'reject');
        if(toReject.length) {
          await this.service('services/cerokm/schemafix').update({query: { _id: {$in: toReject.map(([id,state]) => id)}}}, {$set: {state: 'rejected'}});
          this.setState({ appliedFixes: [], operations: [], temporalFixesState: {}, forceRefresh: new Date().valueOf() });
        }
      }, 'Saving fixes decisions...');
    }
  }

  hasSchemaChanges() {
    return this.state.changedSchema && !_.isEqual(this.state.originalSchema.schema, getCleanSchema(this.state.changedSchema));
  }

  discardChanges() {
    this.setState({ 
      originalSchema: _.cloneDeep(this.state.originalSchema), 
      temporalFixesState: {}, 
      appliedFixes: [] 
    });
  }


  openModalDiffChanges() {
    let inputB = JSON.stringify(getCleanSchema(this.state.changedSchema), null, 2);
    let inputA = JSON.stringify(this.state.originalSchema.schema, null, 2);

    this.modalActionsBus.emit('open', <div>
      <div className={'p-2 bg-light monospace small break-word-all'}>
        <TextDiff type={'json'} inputB={inputB} ellipsis={250} inputA={inputA}/>
      </div>
    </div>, false);
  }
  
  applyFix(ops, fix) {
    try {
      this.editorRef.current.applyOperations(ops);
    } catch(e) {
      return alert(e.toString());
    }
    this.editorRef.current.expandOperationPaths();

    this.setState(prev => ({appliedFixes: [...prev.appliedFixes, fix]}));
    this.setState(prevState => ({ temporalFixesState: { ...prevState.temporalFixesState, [fix._id]: 'accept' } }));  
  }

  rejectFix(fix) {
    this.setState(prevState => ({ temporalFixesState: { ...prevState.temporalFixesState, [fix._id]: 'reject' } }));
  }

  renderPageBody() {
    let editor = <span className={'text-secondary'}>Loading...</span>;

    const { changedSchema, originalSchema, temporalFixesState, forceRefresh, operations } = this.state;

    if (originalSchema) {
      const { schema, createdBy, createdAt, updatedAt } = originalSchema;

      const hasSchemaChanges = this.hasSchemaChanges();
      const changedFixes = !_.isEmpty(temporalFixesState);
      const unsavedChanges = hasSchemaChanges || changedFixes;

      editor = <div className={'grid-schema-editor'}>

        <div className={`d-flex py-2 px-3 align-items-md-center border-bottom ${unsavedChanges ? 'alert-warning' : 'bg-light'}`}
             style={{ minHeight: '50px', gap: '20px' }}>
          <div>
            <div className={'text-secondary zoom-90 small align-middle'} title={createdAt}>
              {createdBy ? <UserAvatar user={createdBy}/> : null} &nbsp;
              {createdAt ?
                <span className={'align-middle'}>Created {fromNow(updatedAt || createdAt)}</span> : null}
            </div>

            <BadgeId id={originalSchema._id}/>
          </div>

          <span className={'badge badge-dark'}>Version {originalSchema.version}</span>

          {unsavedChanges && <div className={'alert alert-warning mb-0 py-1'}>
            { hasSchemaChanges ? 'Unsaved changes' : 'Unsaved fixes decisions' }

            <button className={'btn btn-sm btn-primary ml-2'} onClick={() => this.discardChanges()}>
              Discard
            </button>

            <button className={'btn btn-sm btn-success ml-2 mr-2'} onClick={() => this.saveChanges()}>
              { hasSchemaChanges ? 'Save as new version' : 'Save fixes decisions' }
            </button>

            { hasSchemaChanges && <IconButton icon={'compare'} level={'primary'} description={'See diff of changes'}
                        onClick={this.openModalDiffChanges.bind(this)}>See diff</IconButton>
            }
          </div>}

          {!unsavedChanges && <div className={'alert alert-success mb-0 py-1'}>No changes</div>}
        </div>


      <div className={''}>
        <SchemaEditor key={originalSchema} value={schema} ref={this.editorRef} onChange={(v, operations) => this.setState({ changedSchema: v, operations })}/>
      </div>


      <div className={'bg-light p-2'}>
        <h6>
          Find unmodeled specs and fix the schema
          <IconButton icon={'refresh'} level={'primary'} description={'Refresh'} onClick={() => this.setState({ forceRefresh: new Date().valueOf() })}/>
        </h6>

        <SchemaFixesExplorer 
          onApplyFix={this.applyFix.bind(this)} 
          onRejectFix={this.rejectFix.bind(this)}
          forceRefresh={forceRefresh}
          temporalFixesState={temporalFixesState}
        />
        <pre className={'small zoom-75'}>{JSON.stringify(temporalFixesState, null, 2)}</pre>

        <hr/>
        <SchemaChangeOpsList ops={operations}/>
        <hr/>
        <pre className={'small zoom-75'}>{JSON.stringify(operations, null, 2)}</pre>
      </div>
    </div>;
    }

    return editor;
  }
}

CeroKmSchemaManager.contextType = LegoAdminPageContext;

// For development
function FuncComponent(props) {
  return <CeroKmSchemaManager {...props}/>;
}

export default FuncComponent;
