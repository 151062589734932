import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CRUDPage from './CRUDPage';
import submenuLegos from './menus/submenu-legos';
import { Icon } from '../components/common/Icon';
import { DataIssuesFiltersBar } from '../components/data-issues/DataIssuesFiltersBar';
import DataIssueEditor from '../components/data-issues/DataIssueEditor';
import { OpenClosedIcon } from '../components/data-issues/OpenClosedIcon';
import { StateBadge } from '../components/data-issues/StateBadge';
import NiceDate from '../components/common/NiceDate';
import LegoContextSummary from '../components/lego/LegoContextSummary';
import _ from 'lodash';
import LegoLabel from '../components/lego/LegoLabel';
import ObjectSearchHandler from '../components/common/ObjectSearchHandler';
import ImageClusterPreview from '../components/images/ImageClusterPreview';
import { IconButton } from '../components/common/IconButton';
import ModalBatchEditLabels from '../components/lego/ModalBatchEditLabels';
import downloadFile from '../components/downloadFile';
import { LegoFiltersBar } from '../components/common/LegoFiltersBar';
import MultiButtonSwitch from '../components/common/editors/MultiButtonSwitch';


dayjs.extend(relativeTime);


class ImgClustersManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/data/image-clusters');
    this.submenu = submenuLegos;

    this.state.columnSort = {sorter: (c) => this.clusterMatchingVehicles(c).length, order: 'desc'}
    this.state.previewSize = '50';
    this.canDelete = false;
    this.canEdit = false;
    this.canCreate = false;
  }

  getFacetFiltersComponent() {
    const fields = ['context', 'countries'];
    return <>
      <LegoFiltersBar fields={fields} onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
      <span className={'ml-4 zoom-90'}>
        Thumbnail size: <MultiButtonSwitch value={this.state.previewSize} options={['50', '100', '200']} onChange={size => this.setState({previewSize: size})}/>
      </span>
    </>
  }

  buildObjectQuery() {
    let query = super.buildObjectQuery();


    let contextMake = query['context.make'];
    if(contextMake) {
      query['context.modelId'] = {$regex: `^${contextMake}-`};
      delete query['context.make'];
      query.$limit = 5000;
    }

    let year = query['context.year'];
    if(year) {
      query.images = query.images || {$elemMatch: {}};
      query.images.$elemMatch['vehicle.year'] = parseInt(year.toString());
      delete query['context.year'];
    }

    let countries = query['countries'];
    if(countries) {
      query.images = query.images || {$elemMatch: {}};
      query.images.$elemMatch['vehicle.countryCode'] = countries;
      query['images.vehicle.countryCode'] = countries;
      delete query['countries'];
    }

    console.log("QUERY", query);
    return query;
  }

  getColumnsDefinition(objects) {
    return [
      { content: 'State', className: 'text-center', sorter: 'state' },
      { content: 'Last update', className: 'text-center', sorter: 'updatedAt' },
       ...this.getRankColumnHeaders(),
      { content: 'Context', className: 'text-center', sorter: (c) => this.clusterYears(c).median },
      { content: 'Imgs', className: 'text-center',  sorter: (c) => -this.clusterMatchingVehicles(c).length  },
      { content: 'Data', className: 'text-center',  sorter: (c) => -this.clusterMatchingVehicles(c).length  }
    ];
  }

  clusterMatchingVehicles(cluster) {
    let year = this.state.facetFilters?.context?.year;
    let countries = this.state.facetFilters?.countries;


    return cluster.images.filter(i => (!year || i.vehicle.year === year) && (!countries || countries?.includes(i.vehicle.countryCode)));
  }

  clusterYears(cluster) {
    let years = _.map(cluster.images, 'vehicle.year');
    let min = Math.min(...years);
    let max = Math.max(...years);
    let median = years.sort()[Math.floor(years.length/2)];
    return {min, median, max};
  }

  getObjectColumns(cluster) {
    let {
      _id, createdBy, createdAt, updatedBy, updatedAt, clusterType, images, context, totalImages
    } = cluster;


    let year = this.state.facetFilters?.context?.year;
    let countries = this.state.facetFilters?.countries;


    let matching = this.clusterMatchingVehicles(cluster)

    let maxImages = (this.state.previewSize === '50' ? 20 : this.state.previewSize === '100' ? 10 : this.state.previewSize === '200' ? 5 : 10);

    let {min, median, max} = this.clusterYears(cluster);

    return [
      <td key={'feedbackType'} className={'pl-3 text-center'}>

      </td>,

      <td key={'id'}>
        <NiceDate t={updatedAt}/>
        <div className={'text-secondary small'}>{_id}</div>
      </td>,

      ... this.getRankColumns(cluster),

      <td  key={'context'}>
        <LegoContextSummary context={context || {}}/>
        <div>
          <div className={'text-info'}>~ {median}</div>
          <span className={'text-secondary small'}>{min} - {max}</span>
        </div>
      </td>,


      <td  key={'total'}className={'text-center'}>
        {matching.length !== totalImages ? <span className={'text-primary'}>{matching.length} / </span> :null}
        {totalImages}
      </td>,

      <td  key={'data'}>
        <ImageClusterPreview size={this.state.previewSize} clusterId={_id} highlightYear={year} highlightCountries={countries} maxImages={maxImages} preloadedCluster={cluster}/>
      </td>
    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <DataIssueEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>
  }
}

// For production
// export default ImgClustersManager;

// For development
export default function IMClusterLiveReload(props) {
  return <ImgClustersManager {... props}/>
}
