import _ from 'lodash';


import submenuCeroKm from '../menus/submenu-cerokm';
import CRUDPage from '../CRUDPage';
import NiceDate from '../../components/common/NiceDate';
import MongoDocEditor from '../../components/lego/MongoDocEditor';
import MultilineTextEditor from '../../components/common/editors/MultilineTextEditor';
import HighlightedText from '../../components/common/HighlightedText';

export default class ModelsManager extends CRUDPage {
  constructor(props) {
    super(props, '/services/cerokm/prompts');

    this.submenu = submenuCeroKm;
    this.state.columnSort = { index: 1, asc: true, sorter: ['createdAt'] };

    this.newObjectTemplate = {
      key: '',
      text: '',
      createdBy: this.getLoggedUserSignature(),
    };

    this.canDelete = false;
  }

  getColumnsDefinition(objects) {
    return [
      { content: 'Creation', className: 'text-center', sorter: o => new Date(o.createdAt) },
      { content: 'Key', className: 'text-left', sorter: ['key'] },
      { content: 'Version', className: 'text-center', sorter: ['version'] },
      { content: 'Texto', className: 'text-center' },
    ];
  }

  getObjectColumns(modelObject, def, i, allRows) {
    let { createdAt, key, version, text } = modelObject;

    const paramsRegex = /\$\{([^}]+)\}/g;
    let params = [ ... text.matchAll(paramsRegex)];

    return [
      <td key={'id'}>
        {createdAt && <NiceDate t={createdAt}/>}
      </td>,

      <td key={'key'} className={'text-left'}>
        {key}
      </td>,

      <td key={'version'} className={'text-center'}>
        <span className={'badge badge-dark'}>{version}</span>
      </td>,

      <td key={'text'} className={'text-left'}>
        Params: {params.map(m => <span className={'badge badge-primary mx-1'}>{m[1]}</span>)}
        <pre className={'monospace small text-secondary word-break-all'} style={{whiteSpace: 'pre-wrap', maxHeight: '200px'}}><HighlightedText text={text} regex={paramsRegex}/></pre>
      </td>
    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    let schema = {
      text: (value, onChange) => <MultilineTextEditor maxRows={15} value={value} onChange={onChange}/>
    };

    let onSave = (changedObj, ...args) => {
      return defaultOnSaveCbk(changedObj, ...args);
    };

    return <MongoDocEditor extendSchema={schema} obj={obj} showFields={_.keys(schema)} onSave={onSave}
                           onCancel={defaultOnCancelCbk}/>;
  }
}
