import _ from 'lodash';


import submenuCeroKm from '../menus/submenu-cerokm';
import LivePage from '../LivePage';
import SpecsGroupSelector from './spec-schema-editor/SpecsGroupSelector';
import ModelContextEditor from '../../components/lego/ModelContextEditor';
import BadgeId from '../../components/common/BadgeId';
import { sortObjectKeysDeep } from './spec-schema-editor/SpecSchemaEditor';
import { SpecsJsonTable } from './components/SpecsJsonTable';


class SpecsManager extends LivePage {
  constructor(props) {
    super(props);

    this.svc = this.service('services/cerokm/specs');

    this.state.context = { modelId: 'Toyota-Tacoma', year: 2025 };
    this.submenu = submenuCeroKm;
  }

  async refreshSpecs() {
    if (this.state.selectedGroup?.length) {
      let specs = await Promise.all(this.state.selectedGroup.map((_id) => this.svc.find({ query: { specGroupId: _id } })));
      console.log(specs);
      this.setState({ specs });
    }
  }

  onGroupSelected(group) {
    this.setState({ selectedGroup: group }, () => this.refreshSpecs());
  }

  renderPageBody() {
    const { context, selectedGroup, specs } = this.state;

    let mergedSpecs = [];
    if (specs?.length) {
      for (const json of specs) {
        let merged = {};
        for (let fragment of json) {
          for (let factoid of fragment.factoids) {
            for (let [path, value] of factoid.derivedSpecs) {
              _.set(merged, path, value);
            }
          }
        }

        mergedSpecs.push(sortObjectKeysDeep(merged));
      }
    }


    return <div>
      <ModelContextEditor value={context} onChange={(ctx) => this.setState({ context: ctx })} />
      <SpecsGroupSelector context={context} multi={true} onGroupSelected={this.onGroupSelected.bind(this)} initialSelection={selectedGroup?._id} />

      {_.map(selectedGroup, (_id) => <BadgeId key={_id} id={_id} />)}

      {specs ? <div className=''>
        <SpecsJsonTable specJsons={mergedSpecs} />
      </div> : null}
      {/* <pre>{JSON.stringify(specs, null, 2)}</pre> */}
    </div>;
  }
}

// for development
export default function Component(props) {
  return <SpecsManager {...props} />;
}