import React from 'react';
import _ from 'lodash';

/**
 * UserAvatar component
 *
 * @param {Object} props - Component props
 * @param {string | {username: string, id: string}} [props.user] - User object containing username and id
 * @param {string} [props.id] - User ID
 * @param {string} [props.className] - Optional CSS class name
 * @param {Object} [props.style] - Optional inline styles
 * @param {boolean} [props.autoFocus] - Whether to auto focus the input
 * @param {function} [props.onBlur] - Callback function when the input loses focus
 * @param {string} [props.placeholder] - Placeholder text for the input
 */
export function UserAvatar({ user, id: userId, className = null, ...other }) {
  if (_.isString(user)) {
    user = { username: user, id: userId || user }
  }

  let { username, id } = user || { username: '?', id: userId || 'undefined' };

  let avatarColorIndex = '0';
  const AVAILABLE_COLORS = 48;
  try {
    avatarColorIndex = (parseInt((id || '000').slice(-2), 16) % AVAILABLE_COLORS).toString();
  } catch (err) {
    console.log('Broken avatar-user');
  }

  let initials = (username || '??').replace(/\s/g, '').slice(0, 2).toUpperCase();

  if (id.startsWith('casual-')) {
    initials = '👁‍🗨';
  }

  if (id === 'sheetScript') {
    initials = '🤖'
    avatarColorIndex = "other";
  }

  // @ts-ignore
  return <a href={`${window.config.opinautosUrl}/miembros/perfil/${id}`} target={'_blank'}
    className={`avt${avatarColorIndex} avatar-small gen-avatar align-middle ${className || ''}`} title={username} {...other}>
    <span className={'initials'}>{initials}</span>
  </a>;
}
