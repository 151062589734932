import SchemaTree from '../spec-schema-editor/SchemaTree';
import DisplaySchemaTree from '../spec-schema-editor/DisplaySchemaTree';
import { Icon } from '../../../components/common/Icon';
import TextDiff from '../../../components/test-sets/TextDiff';
import { IconButton } from '../../../components/common/IconButton';
import _ from 'lodash';

const noOp = () => {};


function cleanPropertyPath(path) {
  return path.replaceAll(/(^|\.)properties\./g, ' > ');
}

export function SchemaChangeOpsList({ ops, selection = null, onSelectionChange  = null}) {
  if(!ops?.length) {
    return <span className={'text-muted'}>No operations</span>;
  }

  const opEmojis = {
    add: <Icon icon={'add_circle'} className={'ml-1'}/>,
    addItem: <Icon icon={'playlist_add'} className={'ml-1'}/>,
    remove: <Icon icon={'remove_circle'} className={'ml-1'}/>, 
    move: <Icon icon={'shuffle'} className={'ml-1'}/>,
  }

  return <>{ops.map(({ op, path, fromPath, toPath, value }, i) => {
    let opIcon = opEmojis[op] || op;
    let cleanPropPath = cleanPropertyPath(path || fromPath);

    let element;
    if(op === 'remove') {
      element = <div key={i} className={'text-danger rounded'}>
        {opIcon}
        <span className={'monospace small ml-1'}>{cleanPropPath}</span>
      </div>;
    } else if(op === 'move') {
      element = <div key={i} className={'text-primary rounded monospace small'}>
        {opIcon}
        <TextDiff inputA={cleanPropPath} inputB={cleanPropertyPath(toPath)} type={'words'}	/>
      </div>;
    } else {
      let newObj;
      let displayPropPath = cleanPropPath;
      if (path?.endsWith('.values')) {
        newObj = {
          properties: {
            [displayPropPath.replace(/.values$/, '')]: {
              values: op === 'addItem' ? [value] : value,
              type: 'enum'
            }
          }
        };
      } else {
        newObj = { properties: { [displayPropPath]: value } };
      }

      element = <div key={i} className={'text-success rounded clearfix'}>
        <span className='float-left mr-2'>{opIcon}</span>
        <DisplaySchemaTree schema={newObj}/>
      </div>;
    }

    const isSelected = !selection || selection?.includes(i);
    const toggleCheckbox = () => onSelectionChange?.(isSelected ? _.without(selection || _.range(ops.length), i) : [...(selection || []), i]);

    return <div className={`d-flex ${isSelected ?  '' : 'bg-light translucent'}`} key={i}>
      <span className={selection ? '' :  'parent-hover-transparent'}>
        <IconButton icon={isSelected ? 'check_box' : 'check_box_outline_blank'} className={''} level={'primary'} onClick={toggleCheckbox}/>
      </span>
      <div>
        {element}  
      </div>    
    </div>;
  })}</>;
}
