import _ from 'lodash';
import React from 'react';
import { HotKeys } from 'react-hotkeys';

import PageHeader from '../components/PageHeader';
import LivePage from './LivePage';
import { IconButton } from '../components/common/IconButton';
import ModalManager from '../components/ModalManager';
import ObjectSearchHandler from '../components/common/ObjectSearchHandler';
import GlobalClipboardZone from '../components/common/GlobalClipboardZone';
import { UserAvatar } from '../components/common/UserAvatar';
import DataProcessTemplateEditor from '../components/dataprocess/DataProcessTemplateEditor';
import {Icon} from "../components/common/Icon";
import downloadFile from "../components/downloadFile";
import LoadFileButton from "../components/common/LoadFileButton";
import ImportJSONButton from "../components/common/ImportJSONButton";
import TextSearchInput from '../components/common/TextSearchInput';
import SearchInput from '../components/common/editors/SearchInput';
import {SwitchInput} from "../components/common/SwitchInput";
import { UserAvatarLive } from '../components/common/UserAvatarLive';

export default class CRUDPage extends LivePage {
  constructor(props, serviceEndpoint) {
    super(props);

    this.state = {
      ...this.state,
      facetFilters: this.getUrlParam('filters', true) || {},
      editMode: this.props.editMode,
      numberOfResults: 20,
      allResults: false,
      extraQueryOptions: {},
      textFilter: this.getUrlParam('textFilter') || "",
      selection: [],
      columnSort: null
    };

    this.canDelete = true;
    this.canCreate = true;
    this.canEdit = true;

    // Can be overriden by subclasses
    this.newObjectTemplate = {}
    this.openEditFullScreen = false;


    this.views = this.service(serviceEndpoint);

    this.notificationsService = this.service('/services/common/backend-notifications');

    this.shortcuts.registerKeyMappings({ 'focusSearch': 'ctrl+f', });

    this.shortcuts.registerActionHandlers({
      'focusSearch': this.focusSearch.bind(this),
    });

    this.searchInputRef = React.createRef();

    this.searchHandler = this.getSearchHandler();

    this.handleObjectUpdate = this.handleObjectUpdate.bind(this);
    this.handleObjectCreated = this.handleObjectCreated.bind(this);
    this.handleObjectRemoved = this.handleObjectRemoved.bind(this);
    this.handleBackendNotification = this.handleBackendNotification.bind(this);
  }

  getSearchHandler() {
    // Default text handler
    return new ObjectSearchHandler({}, t => t);
  }

  componentDidMount() {
    super.componentDidMount();

    this.fetchObjects()
      .catch(this.handleError.bind(this));

    let editingId = this.getUrlParam('editing');
    if (editingId) {
      this.loadAndEdit(editingId);
    }

    this.views.on('updated', this.handleObjectUpdate);
    this.views.on('created', this.handleObjectCreated);
    this.views.on('removed', this.handleObjectRemoved);


    this.notificationsService.on('backend-notification', this.handleBackendNotification);
  }

  handleBackendNotification({ level, userLevel, userId, title, message }) {
    if (userLevel === 'admin' && window.user.level !== 'admin') {
      return;
    }

    if (userId && window.user.id !== userId) {
      return;
    }

    this.showNotification({level, title, body: message, time: new Date()});

    console.log(level, title, message);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.views.removeListener('updated', this.handleObjectUpdate);
    this.views.removeListener('created', this.handleObjectCreated);
    this.views.removeListener('removed', this.handleObjectRemoved);
    this.notificationsService.removeListener('backend-notification', this.handleBackendNotification);
  }

  focusSearch() {
    const node = this.searchInputRef.current;
    if(node) {
      node.focus();
      node.select();
    }
    return false;
  }

  async loadAndEdit(id) {
    try {
      let obj = await this.views.get(id);
      if (obj) {
        this.openEdit(obj);
      } else {
        this.handleError(`Could not open document of id '${id}'. Maybe it doesn't exist anymore`)
      }
    } catch (err) {
      this.handleError(`Could not open document of id '${id}'. Maybe it doesn't exist anymore`)
    }
  }

  async fetchObjects(extraQueryOptions = {}) {
    await this.runAsync(async () => {
      const filtersQuery = this.buildObjectQuery();


      let query = { ...filtersQuery, ...extraQueryOptions };

      if(!query.$limit) {
        query.$limit = _.isEmpty(query) ? 20 : 200;
      }

      if(this.state.allResults) {
        query.$limit = 10000;
      }

      // Feth one extra element to know if there are more than $limit results or not
      let originalLimit = query.$limit;
      query.$limit += 1;

      let data = await this.views.find({ query });

      // If there is no query, the server side will be limited to 20 results. Set in state, to warn in UI of this
      // TODO: It would be better if the server indicated somehow that there are more results for a given query
      this.setState({ objects: data.slice(0, originalLimit), isPartialResult: data.length === originalLimit+1 });
      this.orderRows()
    })
  }

  handleError(error) {
    if(error.code === 401) {
      this.openLogin();
    } else {
    // Mongo errors come with a errmsg, if null return toString
      this.modalErrorsBus.emit('open', <div>
        <div className={'alert alert-danger'}>{ error.errmsg || error.toString() }</div>
      </div>, {minSize: false, title: 'There was a problem'});
    }

    console.error(error)
  }

  handleObjectUpdate(obj) {
    let matchingObj = _.findIndex(this.state.objects, o => o._id === obj._id);
    if(matchingObj !== -1) {
      this.state.objects[matchingObj] = obj;
    } else {
      this.state.objects.push(obj);
    }
    this.setState({objects: this.state.objects});
  }

  handleObjectCreated(obj) {
    // Handle as update in case this is the client that created it, so that the old copy is replaced by the DB one
    this.handleObjectUpdate(obj);
  }

  handleObjectRemoved(obj) {
    console.log('removed handler')
    let removedObjs = _.remove(this.state.objects, o => o._id === obj._id);

    if(removedObjs.length) {
      this.setState({ objects: this.state.objects });
    }
  }

  onFiltersChange(newFilters) {
    this.setState({ facetFilters: newFilters, selection: [] });

    this.updateFiltersUrl(newFilters);

    setTimeout(() => this.fetchObjects(), 1);
  }

  updateFiltersUrl(filters) {
    if(_.isEmpty(filters)) {
      this.deleteUrlParam('filters')
    } else {
      this.setUrlParams({filters});
    }
    // document.title = `Lego Builder (${_.values(filters).join(", ")})`
  }

  buildObjectQuery() {
    let query = { ... this.state.extraQueryOptions };
    _.each(this.state.facetFilters, (value, key) => {
      if (value !== undefined) {
        if (_.isArray(value) && value.length) {
          if (value.length === 1) {
            query[key] = value[0];
          } else {
            query[key] = { $in: value };
          }
        } else if(_.isObject(value)) {
          _.each(value, (subValue, valKey) => {
            if (valKey.startsWith('$')) {
              query[key] = value
            } else {
              query[`${key}.${valKey}`] = subValue
            }
          })
        } else if(_.isString(value) && value.startsWith('/') && value.endsWith('/')) {
          query[key] = {$regex: value.slice(1, -1)};
        } else {
          query[key] = value
        }
      }
    });
    return query;
  }

  async delete(view, skipConfirmation = false) {
    if (skipConfirmation || confirm('Are you sure you want to delete it?')) {
      try {
        view.__deleted = true;
        let removedId = view._id;
        delete view._id;
        await this.views.remove(removedId);
      } catch(err) {
        // Rollback
        view.__deleted = false;
        view._id = removedId;
        this.handleError(err);
      }

      this.setState({ objects: this.state.objects });
    }
  }

  async edit(obj, newVersion) {
    newVersion.updatedBy = this.getLoggedUserSignature();
    return await this.views.update(newVersion._id, newVersion);
  }

  async updateOrCreate(obj, changedObj) {
    // changedObj will have _id if is an update, otherwise is a save on an new unsaved original obj
    if (changedObj._id) {
      return await this.edit(obj, changedObj);
    } else {
      changedObj.createdBy = this.getLoggedUserSignature();
      changedObj.updatedBy = changedObj.createdBy;

      let newObj = await this.views.create(changedObj);
      if(_.find(this.state.objects, ({_id}) => _id === newObj._id)) {
        // Update arrived before this
        this.state.objects = _.without(this.state.objects, obj);
        this.setState({objects: this.state.objects})
      } else {
        obj._id = newObj._id;
      }
      return newObj;
    }
  }

  async saveChanges() {
    return this.runAsync((async () => {
      const unsavedObjects = _.filter(this.state.objects, o => o.__new || o.__unsaved);

      for(const obj of unsavedObjects) {
        try {
          let res = await this.updateOrCreate(obj, obj);
          if (obj._id) {
            await this.refreshObject(obj);
          }
        } catch (err) {
          this.handleError(err);
        }
      }
    })())
  }

  /**
   * @param {*} defaultOnSaveCallback
   * @param {function(): boolean} defaultOnCancelCallback
   * @return {JSX.Element}
   */
  async getObjectEditorComponent(obj, defaultOnSaveCallback, defaultOnCancelCallback) {
    throw new Error('Must be implemented by subclass')
  }

  async openEdit(obj) {
    let freshObj = obj;
    if (freshObj && freshObj._id) {
      freshObj = await this.refreshObject(obj);
      this.setUrlParams({ editing: freshObj._id });
    }

    const defaultOnSaveCallback = async(changedObj, closeDialog) => {
      try {
        let res = await this.updateOrCreate(freshObj, changedObj);

        if (closeDialog) {
          this.modalActionsBus.emit('close');
        } else if (freshObj._id) {
          return await this.refreshObject(freshObj);
        }
        return res;
      } catch (err) {
        this.handleError(err);
      }
    };

    const defaultOnCancelCallback = () => this.modalActionsBus.emit('close');

    const objectEditorComponent = await this.getObjectEditorComponent(freshObj, defaultOnSaveCallback, defaultOnCancelCallback);

    this.modalActionsBus.emit('open', <div>{objectEditorComponent}</div>, {
      fullScreen: this.openEditFullScreen,
      onClose: () => this.deleteUrlParam('editing'),
      title: `${obj?._id ? 'Edit' : 'Create'} document`
    });
  }

  async discardUnsaved(obj) {
    this.setState({ objects: _.without(this.state.objects, obj) });
  }

  async copy(obj) {
    // Get a fresh and COMPLETE copy of the object (as lists sometimes use lighter versions)
    if(obj._id) {
      obj = await this.views.get(obj._id);
    }
    let { _id, __v, createdAt, updatedAt, updatedBy, createdBy, ...newObj } = _.cloneDeep(obj);
    newObj.__new = true;
    this.setState({ objects: [newObj].concat(this.state.objects) });
    this.openEdit(newObj);
  }

  selectAll() {
    const filteredRows = this.filterAndSortObjects();

    const selection = [... filteredRows];
    this.setState({selection});
  }

  selectNone() {
    this.setState({selection: []})
  }

  selectRow(row, clickEvent) {
    let selection = [... this.state.selection];
    if(clickEvent && clickEvent.shiftKey) {
      const filteredRows = this.filterAndSortObjects();
      let indices = selection.filter(s => filteredRows.includes(s)).map(row => filteredRows.indexOf(row));
      let min = _.min(indices);
      let max = _.max(indices);
      let r = filteredRows.indexOf(row);
      if(r < min) {
        selection = filteredRows.slice(r, max+1);
      } else {
        selection = filteredRows.slice(min, r+1);
      }
    } else {
      selection = _.uniq([... this.state.selection, row]);
    }
    this.setState({selection})
  }

  unselectRow(row) {
    const selection = _.without(this.state.selection, row);
    this.setState({selection})
  }

  saveSelection() {
    const selectedObjects = this.state.selection;
    const fileName = `exported-legos-${selectedObjects.length}.json`;
    downloadFile(JSON.stringify(selectedObjects, true, 4), fileName, 'text/plain');
  }

  importJSON(data) {
    if (_.isArray(data) && data.length) {
      let newObjects = _.map(data, ({ __new, __deleted, __unsaved, _id, __v, createdAt, updatedAt, ... restOfData }) => {
        return {
          __new: true,
          ...restOfData,
        }
      });

      this.setState({ objects: newObjects.concat(this.state.objects), selection: newObjects });
    } else {
      alert("Invalid file. Should be an array of json objects")
    }
  }

  async refreshObject(obj) {
    let lego = await this.views.get(obj._id);

    if(lego && lego.updatedAt !== obj.updatedAt) {
      this.handleObjectUpdate(lego);
    }

    return lego;
  }

  onAddNewClick(newObjectOverrides = {}) {
    let newObj = {
      __new: true,
      ... (_.cloneDeep(this.newObjectTemplate)),
      ... newObjectOverrides
    };

    this.setState({ objects: [newObj].concat(this.state.objects) });
    this.openEdit(newObj);
  }

  getColumnsDefinition(objects) {
    return [
      {content: 'JSON of the object'}
    ]
  }

  getHeadersRow(objects) {
    let columns = this.getColumnsDefinition(objects);
    let columnSort = this.state.columnSort

    let selectionButton = <IconButton icon={'check_box_outline_blank'} level={'secondary'} onClick={this.selectAll.bind(this)}/>;
    if(this.state.selection.length > 0) {
      if(this.state.selection.length === (this.state.objects || []).length) {
        selectionButton = <IconButton icon={'check_box'} level={'primary'} onClick={this.selectNone.bind(this)}/>;
      } else {
        selectionButton = <IconButton icon={'indeterminate_check_box'} level={'primary'} onClick={this.selectNone.bind(this)}/>;
      }
    }

    return  <tr key={'header'} className={'bg-light'}>
      <th className={'p-0'}>
        { selectionButton }
      </th>
      <th></th>
      {
        columns.map(({ className, content, sorter }, i) => <th key={i} className={className}
                                                               onClick={() => this.onHeaderClick(i, sorter, !columnSort?.asc)}>{content}&nbsp;{columnSort?.index === i ? (columnSort?.asc ? '↑' : '↓') : null}</th>)
      }
      <th></th>
    </tr>;
  }

  onHeaderClick(index, sorter, asc) {
    if (sorter){
      this.setState({ columnSort: { index: index, asc: asc, sorter: sorter } }, () => this.orderRows())
    }
  }

  orderRows () {
    if (_.isNil(this.state.columnSort)){
      return
    }

    const { asc, sorter } = this.state.columnSort
    this.setState({ objects: _.orderBy(this.state.objects, sorter,[asc ? 'asc' : 'desc']) })
  }

  getGroupedHeaderRow(columns) {
    return  <tr key={'header'} className={'bg-light'}>
      <th></th>
      {
        columns.map((content, i) => <th key={i} className={'context-group-column'}>{this.getGrouppingObjectColumn(content)}</th>)
      }
      <th></th>
    </tr>;
  }

  getObjectColumns(obj, definition) {
    return [
      <td key={'data'}>{JSON.stringify(obj)}</td>
    ]
  }

  getFacetFiltersComponent() {
    return null;
  }

  toggleTableMode() {
    this.setState({tableMode: !this.state.tableMode})
  }

  getResultsAsGroupedCells(filteredObjs) {
    // Subclasses must implement this method and more to have this view available
    const {columns, rows} = this.groupObjectsInRows(filteredObjs);

    const groupedObjects = _.toPairs(rows);

    const slicedObjs = groupedObjects.slice(0, this.state.numberOfResults);

    const remainingObjs = groupedObjects.length - slicedObjs.length;

    let showMore = null;
    if (remainingObjs) {
      showMore = <div className={'alert alert-info'}>... and {remainingObjs} more results not shown.
        <span className={'btn btn-primary ml-2'}
              onClick={() => this.setState({ numberOfResults: this.state.numberOfResults + 50 })}>Show more</span>
      </div>;
    }

    const tableRows = _.map(slicedObjs, ([group, objRow]) => {
      let columnsCells = _.map(objRow, (cell,i) => {
        if(!cell) {
          return <td key={'cell'+i} onClick={() => {
            let changedContextCopy = _.cloneDeep(_.compact(objRow)[0].obj);
            //TODO: Hardcoded for LegosManager. Abstract this logic
            changedContextCopy.context = JSON.parse(columns[i]);
            return this.copy(changedContextCopy);
          }}> </td>
        }

        const {obj, isReference} = cell

        let { _id, __unsaved, __new, __deleted, state } = obj;
        let rowClasses = '';

        if(isReference) {
          rowClasses += 'bg-light-primary translucent';
        }

        if (__new) {
          rowClasses += 'bg-light-success';
        } else if (__unsaved) {
          rowClasses += 'bg-light-warning';
        } else if (__deleted) {
          rowClasses += 'bg-light-danger';
        } else if(state === 'inprogress') {
          rowClasses += 'bg-light-patito';
        } else if(state === 'unpublished') {
          rowClasses += 'bg-light-ladrillo';
        }

        let cellKey = columns[i]+(_id || JSON.stringify(obj));

        return <td key={cellKey} onClick={() => this.openEdit(obj)} className={rowClasses}>
          {(obj && this.getObjectCell(obj)) || 'null'}
        </td>
      });

      let columnIndex = <td key={'index'}>{this.getGrouppingObjectRowHeader(_.compact(objRow)[0].obj, group)}</td>

      return <tr key={group} className={columnIndex}>{columnIndex}{columnsCells}</tr>
    });

    return (<div>
            <table key={'groupped-data'} className={'table table-sm table-crud table-mode'}>
              <tbody>
              {this.getGroupedHeaderRow(columns)}
              {tableRows}
              </tbody>
            </table>
            {showMore}
          </div>
    );
  }

  getResultsAsRows(filteredObjs) {
    const slicedObjs = filteredObjs.slice(0, this.state.numberOfResults);

    const remainingObjs = filteredObjs.length - slicedObjs.length;

    let showMore = null;
    if (remainingObjs) {
      showMore = <div className={'alert alert-info'}>... and {remainingObjs} more results not shown.
        <span className={'btn btn-primary ml-2'}
              onClick={() => this.setState({ numberOfResults: this.state.numberOfResults + 50 })}>Show more</span>
      </div>;
    }

    const rows = _.map(slicedObjs, (obj,i) => {
      let { _id, __unsaved, __new, __deleted, state, updatedBy } = obj;
      let isSelected = _.includes(this.state.selection, obj);

      let rowClasses = '';

      if (__new) {
        rowClasses += 'bg-light-success';
      } else if (__unsaved) {
        rowClasses += 'bg-light-warning';
      } else if (__deleted) {
        rowClasses += 'bg-light-danger';
      } else if(state === 'inprogress') {
        rowClasses += 'bg-patito';
      } else if(state === 'unpublished') {
        rowClasses += 'bg-light-secondary';
      }

      if(isSelected) {
        rowClasses += ' row-highlight';
      }



      let actionButtons = [];
      if(true) {
        actionButtons.push()
      }

      let selectButton = null;
      if(isSelected) {
        selectButton = <IconButton icon={'check_box'} level={'primary'} onClick={() => this.unselectRow(obj)}/>
      } else {
        selectButton = <div>
          <IconButton icon={'check_box_outline_blank'} level={'secondary'}  onClick={(e) => this.selectRow(obj, e)}/>
        </div>
      }

      const btnEdit = <IconButton onClick={() => this.openEdit(obj)} level={'primary'} icon={'edit'} description={'Edit'}/>;

      const btnCopy = <IconButton onClick={() => this.copy(obj)} level={'primary'} icon={'file_copy'}
                                  description={'Make a new copy'}/>;

      const btnDiscard = <IconButton onClick={() => this.discardUnsaved(obj)} level={'danger'} icon={'remove'}
                                     description={'Discard unsaved object'}/>;

      const btnDelete = <IconButton onClick={() => this.delete(obj)} level={'danger'} icon={'delete_forever'}
                                     description={'Delete'}/>;

      return <tr
        key={_id || JSON.stringify(obj)} className={rowClasses}>
        <td className={'p-0 '+(this.state.selection.length ? '' : 'parent-hover-transparent')}>
          {selectButton}
        </td>

        <td>
          {updatedBy ? ((updatedBy.userId || updatedBy.id) ? <UserAvatar user={updatedBy}/> : <UserAvatarLive id={updatedBy}/>) : null}
        </td>

        {
          this.getObjectColumns(obj, null, i, slicedObjs)
        }

        <td className={'text-nowrap'}>
          {this.canEdit ? btnEdit : null}
          {this.canCreate ? btnCopy : null}
          {this.canDelete ? (__deleted || __new ? btnDiscard : btnDelete) : null}
        </td>
      </tr>;
    });

    return <div>
      <table key={'row-data'}  className={'table table-sm table-crud'}>
        <tbody>
        {this.getHeadersRow(filteredObjs)}
        {rows}
        </tbody>
      </table>

      {showMore}
    </div>
  }

  getResultsPanel(filteredObjs) {
    if(!this.state.tableMode) {
      return this.getResultsAsRows(filteredObjs);
    } else {
      return this.getResultsAsGroupedCells(filteredObjs);
    }
  }

  getResultsSummary(filteredObjs, objects) {
    return null;
  }

  textFilterChanged(textFilter) {
    // Save in url
    if(textFilter)
      this.setUrlParams({textFilter})
    else
      this.deleteUrlParam('textFilter');

    this.setState({ textFilter, numberOfResults: 20 });
  }

  getSelectionButtons(selection) {
    return null;
  }

  showStats() {
    return null
  }

  filterAndSortObjects() {
    let filteredObjs;

    this.searchHandler.updateFilter(this.state.textFilter || '');

    if(this.state.textFilter) {
      filteredObjs = this.searchHandler.search(this.state.objects || [])

      if (!_.isNil(this.state.columnSort)){
        const { asc, sorter } = this.state.columnSort
        filteredObjs = _.orderBy(filteredObjs, sorter,[asc ? 'asc' : 'desc']);
      }
    } else {
      filteredObjs = (this.state.objects || []);
    }
    return filteredObjs;
  }

  // Rank logic - Optional ======================================================

  getRankColumnHeaders() {
    if(this.state.rankMap) {
      let rank = {content: <span className={'no-wrap'}>
          <IconButton icon={'sync'} onClick={(e) => {
            this.rankObjects(false);
            e.stopPropagation();
          }}/>
          <span>{this.state.rankCriteria}</span>
      </span>, className: 'text-center', sorter: l => this.state.rankMap[l._id] || -1};

      return [rank];
    } else {
      return [];
    }
  }

  getRankColumns(doc) {
    if(this.state.rankMap) {
      let value = this.state.rankMap[doc._id];
      const rank = <td className={'text-center'} key={'rank'} title={this.state.rankCriteria}>{_.isNumber(value) ? value.toLocaleString() : value}</td>
      return [rank];
    } else {
      return [];
    }
  }

  async selectRankCriteria() {
    return new Promise(async (resolve) => {
      let options = await this.rankService.get('');

      const selectRank = (id) => {
        this.setState({rankCriteria: id}, () => resolve(id));
        this.closeModal();
      }

      this.openModal(<div>
        {_.map(options, (description, id) => <div className={'m-1'}>
          <div className={`btn btn-${id === this.state.rankCriteria ? 'primary' : 'secondary'} btn-block`} onClick={() => selectRank(id)}>{description}</div>
        </div>)}
      </div>, { title: 'Select rank criteria', minSize: false });
    });
  }

  getRankQuery() {
    const legoData = this.state.objects.map(({_id, locales, context}) => ({_id, context, locales}));
    return {query: {legos: legoData, rankCriteria: this.state.rankCriteria}};
  }

  async rankObjects(showSelectCriteriaModal = true) {
    const legoData = this.state.objects.map(({_id, locales, context}) => ({_id, context, locales}));

    if(!this.state.rankCriteria || showSelectCriteriaModal) {
      await this.selectRankCriteria();
      if(!this.state.rankCriteria) {
        return;
      }
    }

    this.runAsync(async () => {
      let res = await this.rankService.find(this.getRankQuery())

      const {stats: ranks, rankCriteria} = res;

      console.log("RANKS RESULT", res);

      let objects = this.state.objects;
      let rankMap = {};
      for(const obj of objects) {
        if(ranks[obj._id])
          rankMap[obj._id] = ranks[obj._id];
      }

      this.setState({ rankMap, rankCriteria, columnSort: { index: 1, asc: false, sorter: l => this.state.rankMap[l._id] || -1 } }, () => this.orderRows())
    })
  }

  // ============================================================================

  renderPageBody() {
    const { textFilter, objects, selection } = this.state;


    let filteredObjs = this.filterAndSortObjects();

    let filtersColor = (_.isEmpty(this.state.facetFilters) ? 'bg-secondary' : 'bg-patito')

    let unsavedObjectsCount = _.filter(objects, o => o.__new || o.__unsaved).length;

    const btnSaveChanges = <IconButton level={'primary'} icon={'save'} onClick={() => this.saveChanges()}>
      Save {unsavedObjectsCount} changes</IconButton>

    const btnCreateNew = <IconButton level={'success'} icon={'note_add'} description={'Create new'}
                                     onClick={() => this.onAddNewClick()}/>;

    const btnImportJson = <ImportJSONButton level={'success'} title={'Import from json'} onChange={this.importJSON.bind(this)}/>

    const btnSaveAsJSON = <IconButton icon={'get_app'} title={'Save as JSON'} onClick={() => this.saveSelection()}/>;

    const btnRank = <IconButton icon={'sort'} level={'dark'} title="Rank and sort" onClick={() => this.rankObjects()}/>

    return <div className={''}>
      {/*TODO: Had to add overflowX clip as a weird hack to prevent misterious overflowing content from the flex bar
      from breaking the layout. overflow-x hidden did not work, as also clips menus when opened*/}
      <div className={'sticky-top'} style={{overflowX: 'clip'}}>
      <div className={filtersColor + ' d-flex align-items-center'}>
        <div className={'p-2'}>
          <SearchInput searchHandler={this.searchHandler}
                       refObject={this.searchInputRef}
                       debounce={(objects || []).length > 300}
                       type='text' className={'form-control'}
                       placeholder={'Filter results...'}
                       value={textFilter}
                       style={{width: '260px'}}
                       onChange={this.textFilterChanged.bind(this)}/>
        </div>

        <div style={{width: '130px'}}>
          <div className={'text-black-50 small'}>{(filteredObjs).length} of {(objects || []).length} legos</div>

          {this.state.isPartialResult || this.state.allResults ?
            <div className={'zoom-90'}>
              <SwitchInput value={!!this.state.allResults} onChange={(v) => this.setState({allResults: v}, () => this.fetchObjects())}>
                Fetch all
              </SwitchInput>
            </div> : null}
        </div>

        <div className={'flex-grow-1'}>{this.getFacetFiltersComponent()}</div>

        <div className={'text-right overflow-hidden'}>

          {selection.length ? <span className={'mr-2'}>
            <span className={'inline-block border border-primary px-1 rounded bg-light'}>{btnSaveAsJSON} {this.getSelectionButtons(selection)}</span>
            <span className={'badge badge-primary'}>{selection.length}</span>
          </span> : null}


          { unsavedObjectsCount ? btnSaveChanges : null }

          {this.rankService ? btnRank : null}

          {
            this.groupObjectsInRows ?
              <IconButton level={this.state.tableMode ? 'primary' : 'dark'} icon={'grid_on'}
                          description={'Group legos by context'}
                          onClick={() => this.toggleTableMode()}
              /> : null
          }


          {this.canCreate ? btnImportJson : null}

          {this.canCreate ? btnCreateNew : null}


        </div>
      </div>
      </div>

      { this.getResultsSummary(filteredObjs, objects) }

      { this.showStats() }

      {this.getResultsPanel(filteredObjs)}

      {this.state.isPartialResult ?
        <div className={'m-3 alert alert-danger'}>You are browsing the first {objects.length} results. To see more, apply more filters or turn on this flag:
          <br/>
          <SwitchInput value={!!this.state.allResults} onChange={(v) => this.setState({allResults: v}, () => this.fetchObjects())}>
            Fetch up to 10000 results
          </SwitchInput>
        </div> : null}
    </div>;
  }
}
