import {useEffect} from "react";

/**
 *
 * @param {function} asyncEffect
 * @param {any} [destroyOrInputs]
 * @param {any[]} [inputs]
 */
export default function useAsyncEffect(asyncEffect, destroyOrInputs, inputs) {
  const hasDestroy = typeof destroyOrInputs === 'function';

  useEffect(function () {
    let isMounted = true;
    const maybePromise = asyncEffect(() => isMounted);

    let result;
    Promise.resolve(maybePromise).then(value => result = value);

    return () => {
      isMounted = false;

      if (hasDestroy) {
        destroyOrInputs(result);
      }
    };
  }, hasDestroy ? inputs : destroyOrInputs);
}
