import { useContext, useEffect, useState } from 'react';
import LegoAdminPageContext from '../../legoAdminPageContext';
import useAsyncEffect from '../../../components/common/react-hooks/useAsyncEffect';
import _ from 'lodash';
import { FactoidSpecs } from './FactoidSpecs';
import { SchemaChangeOpsList } from './SchemaChangeOpsList';
import FactoidViewer from './FactoidViewer';
import  { getCleanSchema, sortObjectKeysDeep } from '../spec-schema-editor/SpecSchemaEditor';

function incrementVersionString(version) {
  let [major, minor, patch] = version.split('.');
  patch = patch || '0';
  return `${major}.${minor}.${parseInt(patch) + 1}`;
}

export default function SaveSchemaChangePreview({ operations, changedSchema, originalSchema, appliedFixes, onSavedChanges, fixesState }) {
  const { page } = useContext(LegoAdminPageContext);

  const factoidSvc = page.service('services/cerokm/sourcefactoid');

  const appliedFixesFragmentIds = _.uniq(_.flatten(_.map(appliedFixes, fix => fix.sourceFragmentIds)));

  const [impactData, setImpactData] = useState(null);
  const [impactAppliedFixes, setImpactAppliedFixes] = useState(null);
  
  useAsyncEffect(async () => {
    const res = await Promise.all(operations.map(async ({ op, path, fromPath, toPath, value }) => {
      // Remove .properties of the schema
      let instancePath = (path || fromPath).replaceAll('.properties.', '.').replace(/^properties\./, '');

      // Any key prefixed with the touched
      let pathRegex = { $regex: `^${_.escapeRegExp(instancePath)}($|\\.)` };

      //TODO: Ver otros types y que pasa si nestean el objeto completo
      const $select = { derivedSpecs: 1 };

      switch (op) {
        case 'add':
        case 'remove':
        case 'move':
          console.log(`Checking ${instancePath}`);
          return factoidSvc.find({
            query: {
              'derivedSpecs': { $elemMatch: { '0': pathRegex } }, isObsolete: { $ne: true }, $select
            }
          });        
        case 'addItem':
          return [];
        case 'removeItem':
          throw new Error('Remove item not supported');
      }
    }));

    if(appliedFixesFragmentIds.length) {
      const affected = await factoidSvc.find({ query: { sourceFragmentId: { $in: appliedFixesFragmentIds } , $select: {_id: 1} } });
      setImpactAppliedFixes(affected);
    }

    setImpactData(res);
  }, [operations, originalSchema]);


  function saveChangesToDatabase() {
    page.runAsync(async () => {
      // Increment version, set basedOnVersion and save new doc with changed schema
      let newDoc = {
        ... originalSchema,
        basedOnVersion: originalSchema.version,
        version: incrementVersionString(originalSchema.version),
        createdBy: page.getLoggedUserSignature(),
        schema: sortObjectKeysDeep(getCleanSchema(changedSchema))
      }
      delete newDoc._id;
      delete newDoc.createdAt;
      delete newDoc.updatedAt;

      const svcSchemas = page.service('services/cerokm/schema');
      let savedNewDoc = await svcSchemas.create(newDoc);

      try {      
        const idsToMakeObsolete = _.uniq(_.flatMap(impactData, fs => fs.map(f => f._id)));
        let obsoleteRes = await factoidSvc.update({query: { _id: {$in: idsToMakeObsolete} }}, {$set: {isObsolete: true, obsoleteReason: 'Schema Change '+newDoc.version }});   
        console.log('Obsolete factoids', obsoleteRes);
        
        if (appliedFixes.length) {
          let fixesRes = await page.service('services/cerokm/schemafix').update({query: { _id: {$in: appliedFixes.map(f => f._id)}}}, {$set: {state: 'accepted'}});
          console.log('Aplied Fixes', fixesRes);

          if (appliedFixesFragmentIds.length) {
            let res = await factoidSvc.update({ query: { sourceFragmentId: { $in: appliedFixesFragmentIds } } }, { $set: { isObsolete: true, obsoleteReason: 'Schema Change ' + newDoc.version } });
            console.log('Aplied Fixes Fragment', res);
          }
        }

        let toReject = _.filter(_.toPairs(fixesState), ([id, state]) => state === 'reject');
        if(toReject.length) {
          await page.service('services/cerokm/schemafix').update({query: { _id: {$in: toReject.map(([id,state]) => id)}}}, {$set: {state: 'rejected'}});
        }
      } finally {
        onSavedChanges(savedNewDoc);
      }
    }, 'Saving changes to database...');
  }


  const openFactoidsDetail = factoids => {
    page.openModal(<FactoidViewer ids={_.map(factoids, '_id')}/>, {title: <>Impacted factoids: <span className={'badge badge-primary'}>{factoids.length}</span></>});
  }


  if (impactData) {
    let totalFactoids = _.sum(_.map(impactData, f => f.length)) + (impactAppliedFixes?.length || 0);
  
    return <div className={'p-2'}>
      <table className={'table table-sm bg-light'}>
        <thead className={'bg-dark text-white'}>
        <tr>
          <th></th>
          <th>Operations</th>
          <th className={'text-center'}>Impacted factoids</th>
        </tr>
        </thead>
        <tbody>
        {_.map(impactData, ((factoids, i) => <tr key={i}>
          <td className={'text-secondary small'}>{i + 1}</td>
          <td><SchemaChangeOpsList ops={[operations[i]]}/></td>
          <td className={'text-center'}>
            {
              factoids.length > 0 ?
                <button className={'btn btn-link btn-sm p-0 m-0'}
                        onClick={() => openFactoidsDetail(factoids)}>{factoids.length} factoids</button>
                :
                '-'
            }
          </td>
        </tr>))}

        <tr>
          <td className={'text-secondary small'}></td>

          <td className={'text-center'}>
            Fragments directly involved in applied fixes: {appliedFixesFragmentIds.length}
          </td>

          <td>
            {impactAppliedFixes?.length || '-'}  
          </td>
        </tr>

        <tr>
          <td colSpan={3} className={'bg-warning text-center h6'}>Total: {totalFactoids} obsolete factoids</td>
        </tr>
        </tbody>
      </table>

      <div className={'p-2 text-center'}>
        <span className={'btn btn-primary'} onClick={saveChangesToDatabase}>Proceed to save changes and mark <span
          className={'badge badge-dark'}>{totalFactoids}</span> factoids obsolete</span>
      </div>
    </div>;
  } else {
    return <div className={'p-2'}>Loading...</div>;
  }
}
