import _ from 'lodash';
import { useState } from 'react';

const typeStyles = {
  'unit': {backgroundColor: 'rgba(149,205,214,0.2)'},
  'number': {backgroundColor: '#f6e4bd'},
  'enum': {backgroundColor: 'rgba(33,161,33,0.13)', color: 'green'},
  string: {backgroundColor: '#c9bdf6'},
  boolean: {backgroundColor: '#999', color: 'white'},
  array: {backgroundColor: '#bfddfa', color: 'gray'},
  'objectTypedKeys': {backgroundColor: 'yellow'},
}

export default function DisplaySchemaTree({ schema }) {
  const [collapsedNodes, setCollapsedNodes] = useState({});

  // Toggle expand/collapse state for nodes
  const toggleExpand = (path) => {
    setCollapsedNodes(prev => ({
      ...prev,
      [path]: !prev[path]
    }));
  };

  return <div className="schema-tree monospace small">
    {schema.properties && <div className="properties-container ml-4">
      {Object.entries(schema.properties).map(([key, value]) => (
        <DisplaySchemaNode
          key={key}
          nodeName={key}
          nodeValue={value}
          path="properties"
          collapsedNodes={collapsedNodes}
          toggleExpand={toggleExpand}
          level={0}
        />
      ))}
    </div>}
  </div>;
};

// Display-only version of SchemaNode
const DisplaySchemaNode = ({
                             nodeName,
                             nodeValue,
                             path,
                             collapsedNodes,
                             toggleExpand,
                             level
                           }) => {
  const fullPath = `${path}.${nodeName}`;
  const isExpanded = collapsedNodes[fullPath] !== true; // Default to expanded
  const isObject = nodeValue?.type === 'object';

  const hasChildren = nodeValue && ((isObject && nodeValue.properties)
    || (nodeValue.type === 'objectTypedKeys' && nodeValue.keys && nodeValue.valuesType)
    || (nodeValue.type === 'array' && nodeValue.items)
    || (nodeValue.type === 'union' && nodeValue.types));

  // Render different components based on the node type
  const renderNodeContent = () => {
    const recursiveProps = {
      collapsedNodes,
      toggleExpand,
      level: level + 1
    }

    if (isObject && nodeValue.properties) {
      const propertyEntries = Object.entries(nodeValue.properties);

      return <div className="ml-1 pl-2 border-left border-light-secondary">
        {propertyEntries.map(([propName, propValue]) => (
          <DisplaySchemaNode
            key={propName}
            nodeName={propName}
            nodeValue={propValue}
            path={`${fullPath}.properties`}
            {...recursiveProps}
          />
        ))}

        {propertyEntries.length === 0 ?
          <div className="text-muted fst-italic ml-2">{"<no properties>"}</div> : null}
      </div>;
    }

    // Handling for objectTypedKeys
    if (nodeValue.type === 'objectTypedKeys') {
      return <div className="ml-0">
        <div>
          <div className="ml-1">
            {nodeValue.keys.map((val, i) => (<span
                key={i}
                className="bg-success rounded zoom-90 text-white mr-1 px-2"
              >
                {val}
              </span>))}
          </div>
        </div>
        <DisplaySchemaNode nodeName="valuesType" nodeValue={nodeValue.valuesType} path={fullPath}{...recursiveProps}/>
      </div>;
    }

    // Handling for array items
    if (nodeValue.type === 'array' && nodeValue.items) {
      return <div className="ml-0">
        <DisplaySchemaNode nodeName="items" nodeValue={nodeValue.items} path={fullPath}{...recursiveProps}/>
      </div>;
    }

    // Union types
    if (nodeValue.type === 'union' && nodeValue.types) {
      return <div className="ml-4">
        <div className="fw-bold mb-1">Union Types:</div>
        {nodeValue.types.map((type, index) => (
          <DisplaySchemaNode key={index} nodeName={`type${index + 1}`} nodeValue={type} path={`${fullPath}.types`}{...recursiveProps}/>
        ))}
      </div>;
    }

    // Enum values
    if (nodeValue.type === 'enum' && nodeValue.values) {
      return <div className="ml-1">
        {nodeValue.values.map((val, i) => (
          <span key={i} className="bg-success zoom-90 text-white mr-1 px-2 rounded">
            {val}
          </span>
        ))}
      </div>;
    }

    // Unit type
    if (nodeValue.type === 'unit') {
      return <span className="ml-1">
        <span
          className="text-secondary px-1 align-middle bg-light-info font-weight-bold border border-info"
          style={{fontSize: '12px'}}
        >
          {nodeValue.unit}
        </span>
      </span>;
    }

    // For simple types with no children
    return null;
  };

  const showInSameLine = !(isObject || nodeValue.type === 'objectTypedKeys' || nodeValue.type === 'array');

  const comment = nodeValue?._comments ? (
    <span className="ml-4 mr-3 text-secondary font-italic no-wrap flex-grow-1 text-right zoom-90">
      //{nodeValue._comments}
    </span>
  ) : null;

  return <div
    className={`schema-node pl-2 mb-${isObject ? 0 : 0} ${level > 0 ? 'ml-2' : ''}`}
    style={{ borderColor: '#dee2e6' }}
  >
    <div
      className={`d-flex align-items-baseline pb-0 spec-schema-node`}
      style={hasChildren ? { marginLeft: '-14px' } : {}}
    >
      {hasChildren &&
        <button className="btn btn-sm mr-1 p-0 text-secondary" onClick={() => toggleExpand(fullPath)}>
          {isExpanded ? '▼' : '►'}
        </button>
      }

      <span className="mr-2">
        {nodeName}:
      </span>

      <div className="d-flex align-items-center">
        <span className="btn btn-sm zoom-90 py-0" disabled={true} style={typeStyles[nodeValue?.type] || {}}>
          {isObject ? null : nodeValue?.type}
        </span>
      </div>

      {isObject && isExpanded ? comment : null}

      {isExpanded && showInSameLine && renderNodeContent()}

      {!isExpanded && isObject ?
        <span className="bg-light rounded px-1" onClick={() => toggleExpand(fullPath)}>
          {"{ ... }"}
        </span> : null}

      {!isObject || !isExpanded ? comment : null}
    </div>

    {isExpanded && !showInSameLine && renderNodeContent()}
  </div>;
};
